import React, { useRef } from 'react';
import '../styles/TestimonialsHome.css';
import { BiChevronLeftCircle, BiChevronRightCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { SMP_FOR_ALOPECIA_URL_1, SMP_FOR_ALOPECIA_URL_2, SMP_FOR_SCARS_URL_1, SMP_FOR_SCARS_URL_2, TREATMENTS_HEADING_1, TREATMENTS_HEADING_2, SMP_FOR_MEN_CARD_TITLE, SMP_FOR_MEN_CARD_DESCRIPTION, SMP_FOR_MEN_CARD_FOOTER, SMP_FOR_WOMEN_CARD_TITLE, SMP_FOR_WOMEN_CARD_DESCRIPTION, SMP_FOR_WOMEN_CARD_FOOTER, SMP_FOR_ALOPECIA_CARD_TITLE, SMP_FOR_ALOPECIA_CARD_DESCRIPTION, SMP_FOR_ALOPECIA_CARD_FOOTER, SMP_FOR_SCARS_CARD_TITLE, SMP_FOR_SCARS_CARD_DESCRIPTION, SMP_FOR_SCARS_CARD_FOOTER } from '../constants';
import { MdOutlineStar } from "react-icons/md";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function TestimonialsHome() {
    return (
        <div>
            <TestimonialsHomeMainDesktop></TestimonialsHomeMainDesktop>
            <TestimonialsHomeMainTablet></TestimonialsHomeMainTablet>
            <TestimonialsHomeMainMobile></TestimonialsHomeMainMobile>
        </div>
    );
};

function TestimonialsHomeMainDesktop() {
    const navigate = useNavigate();
    const styles = {
        buttonContainer: {
            // backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItms: 'center',
            fontSize: '30px',
            margin: '0px 5px 0px 0px'
        },
        icon: {
            cursor: 'pointer',
            color: '#c5c5c5'
        },
    };

    const scrollRef = useRef(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            const { scrollLeft } = scrollRef.current;
            if (scrollLeft > 0) {  // Only scroll if not already at the start
                scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
            }
        }
    };
    
    const scrollRight = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            const maxScroll = scrollWidth - clientWidth;
    
            if (scrollLeft < maxScroll) {  // Only scroll if not already at the end
                scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
            }
        }
    };

    return (
        <div className='testimonials-home-main-desktop'>
            <div className='testimonials-home-head'>
                <div className='testimonials-home-head-column'>
                    {/* <div className='testimonials-home-text'>
                        TESTIMONIALS
                    </div> */}
                    <div className='testimonials-home-text2'>
                        Reviews
                    </div>
                </div>
                <div style={styles.buttonContainer}>
                    <BiChevronLeftCircle onClick={scrollLeft} style={styles.icon} />
                    <div style={{ width: '15px' }}></div>
                    <BiChevronRightCircle onClick={scrollRight} style={styles.icon} />
                </div>
            </div>
            <div ref={scrollRef} className='testimonial-home-cards'>
                <TestimonialCard
                    text1="Nikhil Kelji"
                    text2="I knew I had to get the smp done, but I was not getting the right artist to get it done as there are only 2-3 artists in India who specialize in smp and smp only. Luckily got my appointments for this guy and got what I exactly wanted. Chetan is not just an excellent smp artist but also a good human being. He loves his job and has a knack for hygeine making me more comfortable about the procedure. What I loved about him was his dedication. From start to the end of the process, he worked with the same enthu and energy. He did a fabulous job and I wish he continues to spread smiles in future. Kudos bro keep doing the good work and godspeed."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Joaquim Carvalho"
                    text2="I'm thrilled to share my experience with Micro Inc. SMP and the incredibly talented Mr. Chetan Mahajan! His expertise in scalp pigmentation is unmatched, and his guidance was impeccable. In just two sessions, he transformed my look with a remarkably natural appearance. His attention to detail, professionalism, and dedication are truly commendable. The clinic itself is a serene oasis, designed for comfort. If you're considering scalp pigmentation, look no further than Micro Inc. SMP and Mr. Mahajan. His artistry and the clinic's exceptional service will exceed your expectations."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="John Mumbai"
                    text2="Literally the BEST PERSON to go to in Mumbai Atleast , for Your SMP needs ! Mr. Chetan is a well-trained Professional, Considerate about your scalp needs and Fantastic in his SMP work , providing EXCELLENT RESULTS ! This Gentleman starts with a Thorough Explanation of the procedure , its Suitability/Non-suitability for u , answers all yr queries and clears all your doubts. I went through my Sessions Easily in his Comfortable Studio and now am Thoroughly Satisfied with the Results !!!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Vishal Jaiswar"
                    text2="A few years ago, I started losing my hair, and whatever was left began thinning too. I lost almost 80% of it. But honestly, the hardest part wasn’t just the hair loss, it was how it took away my confidence. I started avoiding people and felt very low. I knew SMP could help, but I never thought it would look this good or this natural. It’s been a total game changer for me. My confidence is back, and the compliments I’ve been getting from friends and family feel amazing. If you’re dealing with hair loss, I’d 100% recommend SMP over anything else. A huge thanks to Chetan Sir for giving me this fresh, new look!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Praveen Tirkey"
                    text2="I discovered SMP while researching hair transplants. The more I learned, the more I realized it was the perfect choice. While searching for SMP studios in Mumbai, most were general cosmetic clinics, but you stood out as a true specialist. Knowing you had undergone SMP yourself and reading your reviews gave me complete confidence. From our first meeting, I felt comfortable and certain in my decision. Now, my confidence is boosted, my family loves my new look, and I couldn’t be happier. Micro Inc. SMP is the best choice!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="MyHome Engineering"
                    text2="I recently underwent two Scalp Micropigmentation (SMP) sessions at Micro Inc, and it has been an outstanding experience. The studio is conveniently near Mahim Station, making it easily accessible. Mr. Chetan Mahajan handled the process with great expertise and attention to detail. The relaxing atmosphere, complemented by cool background music, made it even better. The results are precise and natural-looking. With one session left, I’m already thrilled. His hospitality, including a thoughtful meal, stood out. I confidently give Micro Inc. SMP a 5-star rating. Highly recommended!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Nilesh More"
                    text2="It was a wonderful experience with Chetan sir in his studio of Micro Inc. SMP. His experience, Passion and dedicated work is remarkable. Good Hygiene was maintained, pleasant atmosphere. He works with all his energy and enthusiasm and make client comfortable throughout the sessions . The most important thing is client satisfaction, he works till that extent where client gets satisfied from his work, Really Worth for money."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Vinod Bhagat"
                    text2="I don't usually write reviews, but I felt compelled to share my experience with Micro Inc SMP because of the exceptional quality of his work. The art of Scalp Micropigmentation (SMP) requires skill and finesse, and Chetan Mahajan from Micro Inc SMP is a Master. This procedure has been transformative. He gave me the confident I needed and a new look in life. He's such a perfectionist and took the time to do it right and make sure I'm happy with the result for anyone feeling stuck and looking for a change, I highly recommend Chetan’s Micro Inc SMP. Choosing Chetan Mahajan for your SMP treatment is a decision you won't regret. Forever grateful!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Clara Thomas"
                    text2="I dealt with hair loss as a woman for the past 3 years. I wasn't confident about how I looked. I tried almost every possible treatment to improve the condition but I wasn't happy. I was nervous at the start of the process but Chetan walked me through, explaining what he will be doing and made me comfortable. It was a painless process. Now my confidence is back and I can style and wear my hair in any way I want too. I can't thank Micro Inc. SMP enough for helping me bring this change in my life."
                    text3={SMP_FOR_WOMEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforwomen/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Geroge Fernandes"
                    text2="I recently had an exceptional experience at Micro Inc. SMP! The expertise at Micro Inc. SMP shone through as they patiently addressed all my queries, instilling in me a sense of confidence about the procedure. Under the skilled hands of Chetan Mahajan, I achieved natural-looking results that have significantly bolstered my self-assurance. Moreover, the minimal post-procedure care required is a definite advantage. I wholeheartedly recommend Micro Inc. for those seeking a non-surgical hair loss solution – it truly transformed my life! Special thanks to Chetan Mahajan for his outstanding work! As someone who sought hair treatment, I can confidently say that it's worth every penny."
                    text3={SMP_FOR_MEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpformen/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Uday Karkera"
                    text2="Few years back, I was suggested to opt for a patch from a repute clinic as I lacked sufficient donor area for a hair transplant, a suggestion I didn't pursue. Recently, a close friend recommended I explore Micro Inc. SMP for SMP. There, I met Chetan Mahajan, the Picasso I would say, in his Mahim studio. The procedure itself was painless, although each session required a few hours at a stretch. Chetan's skills and crafty hands ensured my comfort throughout. This innovation is truly remarkable, offering individuals with sparse hair the opportunity to transform their appearance and regain their confidence. Now, I confidently affirm that 50 is the new 40 :)"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Ronak Motta"
                    text2="I recently underwent the scalp micropigmentation procedure, and I couldn't be more thrilled with the results. As someone who has struggled with hair loss for years and have had a bald scalp, SMP has been a game-changer for me. From the initial consultation to the final session, the entire experience at Micro Inc. SMP was exceptional. The results exceeded my expectations. The skilled practitioner (Chetan Mahajan) meticulously created a natural-looking hairline that seamlessly blends with my existing hair. It's amazing how the treatment has significantly improved my confidence and overall appearance. “Yay I have a Hairline now”"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
            </div>
        </div>
    );
}

function TestimonialsHomeMainTablet() {
    const navigate = useNavigate();
        const scrollRef = useRef(null);
    
        const scrollLeft = () => {
            if (scrollRef.current) {
                const { scrollLeft } = scrollRef.current;
                if (scrollLeft > 0) {  // Only scroll if not already at the start
                    scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
                }
            }
        };
        
        const scrollRight = () => {
            if (scrollRef.current) {
                const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
                const maxScroll = scrollWidth - clientWidth;
        
                if (scrollLeft < maxScroll) {  // Only scroll if not already at the end
                    scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
                }
            }
        };
    return (
        <div className='testimonials-home-main-tablet'>
            <div className='testimonials-home-head'>
                <div className='testimonials-home-head-column'>
                    {/* <div className='testimonials-home-text'>
                        TESTIMONIALS
                    </div> */}
                    <div className='testimonials-home-text2'>
                        Reviews
                    </div>
                </div>
            </div>
            <div style={{ 
                width: "80%",
                position: "relative", 
                display: "flex",
            }}>
            <div ref={scrollRef} className='testimonial-home-cards1'>
                <TestimonialCard
                    text1="Nikhil Kelji"
                    text2="I knew I had to get the smp done, but I was not getting the right artist to get it done as there are only 2-3 artists in India who specialize in smp and smp only. Luckily got my appointments for this guy and got what I exactly wanted. Chetan is not just an excellent smp artist but also a good human being. He loves his job and has a knack for hygeine making me more comfortable about the procedure. What I loved about him was his dedication. From start to the end of the process, he worked with the same enthu and energy. He did a fabulous job and I wish he continues to spread smiles in future. Kudos bro keep doing the good work and godspeed."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Joaquim Carvalho"
                    text2="I'm thrilled to share my experience with Micro Inc. SMP and the incredibly talented Mr. Chetan Mahajan! His expertise in scalp pigmentation is unmatched, and his guidance was impeccable. In just two sessions, he transformed my look with a remarkably natural appearance. His attention to detail, professionalism, and dedication are truly commendable. The clinic itself is a serene oasis, designed for comfort. If you're considering scalp pigmentation, look no further than Micro Inc. SMP and Mr. Mahajan. His artistry and the clinic's exceptional service will exceed your expectations."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="John Mumbai"
                    text2="Literally the BEST PERSON to go to in Mumbai Atleast , for Your SMP needs ! Mr. Chetan is a well-trained Professional, Considerate about your scalp needs and Fantastic in his SMP work , providing EXCELLENT RESULTS ! This Gentleman starts with a Thorough Explanation of the procedure , its Suitability/Non-suitability for u , answers all yr queries and clears all your doubts. I went through my Sessions Easily in his Comfortable Studio and now am Thoroughly Satisfied with the Results !!!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Vishal Jaiswar"
                    text2="A few years ago, I started losing my hair, and whatever was left began thinning too. I lost almost 80% of it. But honestly, the hardest part wasn’t just the hair loss, it was how it took away my confidence. I started avoiding people and felt very low. I knew SMP could help, but I never thought it would look this good or this natural. It’s been a total game changer for me. My confidence is back, and the compliments I’ve been getting from friends and family feel amazing. If you’re dealing with hair loss, I’d 100% recommend SMP over anything else. A huge thanks to Chetan Sir for giving me this fresh, new look!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Praveen Tirkey"
                    text2="I discovered SMP while researching hair transplants. The more I learned, the more I realized it was the perfect choice. While searching for SMP studios in Mumbai, most were general cosmetic clinics, but you stood out as a true specialist. Knowing you had undergone SMP yourself and reading your reviews gave me complete confidence. From our first meeting, I felt comfortable and certain in my decision. Now, my confidence is boosted, my family loves my new look, and I couldn’t be happier. Micro Inc. SMP is the best choice!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="MyHome Engineering"
                    text2="I recently underwent two Scalp Micropigmentation (SMP) sessions at Micro Inc, and it has been an outstanding experience. The studio is conveniently near Mahim Station, making it easily accessible. Mr. Chetan Mahajan handled the process with great expertise and attention to detail. The relaxing atmosphere, complemented by cool background music, made it even better. The results are precise and natural-looking. With one session left, I’m already thrilled. His hospitality, including a thoughtful meal, stood out. I confidently give Micro Inc. SMP a 5-star rating. Highly recommended!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Nilesh More"
                    text2="It was a wonderful experience with Chetan sir in his studio of Micro Inc. SMP. His experience, Passion and dedicated work is remarkable. Good Hygiene was maintained, pleasant atmosphere. He works with all his energy and enthusiasm and make client comfortable throughout the sessions . The most important thing is client satisfaction, he works till that extent where client gets satisfied from his work, Really Worth for money."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Vinod Bhagat"
                    text2="I don't usually write reviews, but I felt compelled to share my experience with Micro Inc SMP because of the exceptional quality of his work. The art of Scalp Micropigmentation (SMP) requires skill and finesse, and Chetan Mahajan from Micro Inc SMP is a Master. This procedure has been transformative. He gave me the confident I needed and a new look in life. He's such a perfectionist and took the time to do it right and make sure I'm happy with the result for anyone feeling stuck and looking for a change, I highly recommend Chetan’s Micro Inc SMP. Choosing Chetan Mahajan for your SMP treatment is a decision you won't regret. Forever grateful!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Clara Thomas"
                    text2="I dealt with hair loss as a woman for the past 3 years. I wasn't confident about how I looked. I tried almost every possible treatment to improve the condition but I wasn't happy. I was nervous at the start of the process but Chetan walked me through, explaining what he will be doing and made me comfortable. It was a painless process. Now my confidence is back and I can style and wear my hair in any way I want too. I can't thank Micro Inc. SMP enough for helping me bring this change in my life."
                    text3={SMP_FOR_WOMEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforwomen/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Geroge Fernandes"
                    text2="I recently had an exceptional experience at Micro Inc. SMP! The expertise at Micro Inc. SMP shone through as they patiently addressed all my queries, instilling in me a sense of confidence about the procedure. Under the skilled hands of Chetan Mahajan, I achieved natural-looking results that have significantly bolstered my self-assurance. Moreover, the minimal post-procedure care required is a definite advantage. I wholeheartedly recommend Micro Inc. for those seeking a non-surgical hair loss solution – it truly transformed my life! Special thanks to Chetan Mahajan for his outstanding work! As someone who sought hair treatment, I can confidently say that it's worth every penny."
                    text3={SMP_FOR_MEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpformen/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Uday Karkera"
                    text2="Few years back, I was suggested to opt for a patch from a repute clinic as I lacked sufficient donor area for a hair transplant, a suggestion I didn't pursue. Recently, a close friend recommended I explore Micro Inc. SMP for SMP. There, I met Chetan Mahajan, the Picasso I would say, in his Mahim studio. The procedure itself was painless, although each session required a few hours at a stretch. Chetan's skills and crafty hands ensured my comfort throughout. This innovation is truly remarkable, offering individuals with sparse hair the opportunity to transform their appearance and regain their confidence. Now, I confidently affirm that 50 is the new 40 :)"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Ronak Motta"
                    text2="I recently underwent the scalp micropigmentation procedure, and I couldn't be more thrilled with the results. As someone who has struggled with hair loss for years and have had a bald scalp, SMP has been a game-changer for me. From the initial consultation to the final session, the entire experience at Micro Inc. SMP was exceptional. The results exceeded my expectations. The skilled practitioner (Chetan Mahajan) meticulously created a natural-looking hairline that seamlessly blends with my existing hair. It's amazing how the treatment has significantly improved my confidence and overall appearance. “Yay I have a Hairline now”"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
            </div>
                        <button style={{
                              'position': 'absolute',
                              'top': '50%',
                              'transform': 'translateY(-50%)',
                              'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                              'color': 'white',
                              'border': 'none',
                              'padding': '10px 10px 10px 5px',
                              'borderRadius': '50%',
                              'width': '40px',
                              'height': '80px',
                              'borderRadius': '0px 45px 45px 0',
                              'cursor': 'pointer',
                              'zIndex': '1',
                              'display': 'flex',
                              'alignItems': 'center',
                              'justifyContent': 'center',
                              'left': '0px',
                          }} onClick={() => scrollLeft()}>
                          <FaChevronLeft size={25} />
                        </button>
                        <button style={{
                              'position': 'absolute',
                              'top': '50%',
                              'transform': 'translateY(-50%)',
                              'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                              'color': 'white',
                              'border': 'none',
                              'padding': '10px 5px 10px 10px',
                              'borderRadius': '50%',
                              'width': '40px',
                              'height': '80px',
                              'borderRadius': '45px 0 0 45px',
                              'cursor': 'pointer',
                              'zIndex': '1',
                              'display': 'flex',
                              'alignItems': 'center',
                              'justifyContent': 'center',
                              'right': '0px',
                          }} onClick={() => scrollRight()}>
                          <FaChevronRight size={25} />
                        </button>
            </div>
            {/* <div className='testimonial-home-cards2'>
                <TestimonialCard
                    text1="Ronak Motta"
                    text2="I recently underwent the scalp micropigmentation procedure, and I couldn't be more thrilled with the results. As someone who has struggled with hair loss for years and have had a bald scalp, SMP has been a game-changer for me. From the initial consultation to the final session, the entire experience at Micro Inc. SMP was exceptional. The results exceeded my expectations. The skilled practitioner (Chetan Mahajan) meticulously created a natural-looking hairline that seamlessly blends with my existing hair. It's amazing how the treatment has significantly improved my confidence and overall appearance. “Yay I have a Hairline now”"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <TestimonialCard
                    text1="Nilesh More"
                    text2="It was a wonderful experience with Chetan sir in his studio of Micro Inc. SMP. His experience, Passion and dedicated work is remarkable. Good Hygiene was maintained, pleasant atmosphere. He works with all his energy and enthusiasm and make client comfortable throughout the sessions . The most important thing is client satisfaction, he works till that extent where client gets satisfied from his work, Really Worth for money."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
            </div> */}
        </div>
    );
}

function TestimonialsHomeMainMobile() {
    const navigate = useNavigate();
    const styles = {
        buttonContainer: {
            // backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItms: 'center',
            fontSize: '30px',
            margin: '0px 5px 0px 0px'
        },
        icon: {
            cursor: 'pointer',
            color: '#c5c5c5'
        },
    };

    const scrollRef = useRef(null);
    
    const scrollLeft = () => {
        if (scrollRef.current) {
            const { scrollLeft } = scrollRef.current;
            if (scrollLeft > 0) {  // Only scroll if not already at the start
                scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
            }
        }
    };
    
    const scrollRight = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            const maxScroll = scrollWidth - clientWidth;
    
            if (scrollLeft < maxScroll) {  // Only scroll if not already at the end
                scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
            }
        }
    };

    return (
        <div className='testimonials-home-main-mobile'>
            <div className='testimonials-home-head'>
                <div className='testimonials-home-head-column'>
                    {/* <div className='testimonials-home-text'>
                        TESTIMONIALS
                    </div> */}
                    <div className='testimonials-home-text2'>
                        Reviews
                    </div>
                </div>
                {/* <div style={styles.buttonContainer}>
                    <BiChevronLeftCircle onClick={scrollLeft} style={styles.icon} />
                    <div style={{ width: '15px' }}></div>
                    <BiChevronRightCircle onClick={scrollRight} style={styles.icon} />
                </div> */}
            </div>
            <div style={{ 
                width: "90%",
                position: "relative", 
                display: "flex",
            }}>
            <div ref={scrollRef} className='testimonial-home-cards'>
                <TestimonialCard
                    text1="Nikhil Kelji"
                    text2="I knew I had to get the smp done, but I was not getting the right artist to get it done as there are only 2-3 artists in India who specialize in smp and smp only. Luckily got my appointments for this guy and got what I exactly wanted. Chetan is not just an excellent smp artist but also a good human being. He loves his job and has a knack for hygeine making me more comfortable about the procedure. What I loved about him was his dedication. From start to the end of the process, he worked with the same enthu and energy. He did a fabulous job and I wish he continues to spread smiles in future. Kudos bro keep doing the good work and godspeed."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Joaquim Carvalho"
                    text2="I'm thrilled to share my experience with Micro Inc. SMP and the incredibly talented Mr. Chetan Mahajan! His expertise in scalp pigmentation is unmatched, and his guidance was impeccable. In just two sessions, he transformed my look with a remarkably natural appearance. His attention to detail, professionalism, and dedication are truly commendable. The clinic itself is a serene oasis, designed for comfort. If you're considering scalp pigmentation, look no further than Micro Inc. SMP and Mr. Mahajan. His artistry and the clinic's exceptional service will exceed your expectations."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="John Mumbai"
                    text2="Literally the BEST PERSON to go to in Mumbai Atleast , for Your SMP needs ! Mr. Chetan is a well-trained Professional, Considerate about your scalp needs and Fantastic in his SMP work , providing EXCELLENT RESULTS ! This Gentleman starts with a Thorough Explanation of the procedure , its Suitability/Non-suitability for u , answers all yr queries and clears all your doubts. I went through my Sessions Easily in his Comfortable Studio and now am Thoroughly Satisfied with the Results !!!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Vishal Jaiswar"
                    text2="A few years ago, I started losing my hair, and whatever was left began thinning too. I lost almost 80% of it. But honestly, the hardest part wasn’t just the hair loss, it was how it took away my confidence. I started avoiding people and felt very low. I knew SMP could help, but I never thought it would look this good or this natural. It’s been a total game changer for me. My confidence is back, and the compliments I’ve been getting from friends and family feel amazing. If you’re dealing with hair loss, I’d 100% recommend SMP over anything else. A huge thanks to Chetan Sir for giving me this fresh, new look!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Praveen Tirkey"
                    text2="I discovered SMP while researching hair transplants. The more I learned, the more I realized it was the perfect choice. While searching for SMP studios in Mumbai, most were general cosmetic clinics, but you stood out as a true specialist. Knowing you had undergone SMP yourself and reading your reviews gave me complete confidence. From our first meeting, I felt comfortable and certain in my decision. Now, my confidence is boosted, my family loves my new look, and I couldn’t be happier. Micro Inc. SMP is the best choice!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="MyHome Engineering"
                    text2="I recently underwent two Scalp Micropigmentation (SMP) sessions at Micro Inc, and it has been an outstanding experience. The studio is conveniently near Mahim Station, making it easily accessible. Mr. Chetan Mahajan handled the process with great expertise and attention to detail. The relaxing atmosphere, complemented by cool background music, made it even better. The results are precise and natural-looking. With one session left, I’m already thrilled. His hospitality, including a thoughtful meal, stood out. I confidently give Micro Inc. SMP a 5-star rating. Highly recommended!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Nilesh More"
                    text2="It was a wonderful experience with Chetan sir in his studio of Micro Inc. SMP. His experience, Passion and dedicated work is remarkable. Good Hygiene was maintained, pleasant atmosphere. He works with all his energy and enthusiasm and make client comfortable throughout the sessions . The most important thing is client satisfaction, he works till that extent where client gets satisfied from his work, Really Worth for money."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Vinod Bhagat"
                    text2="I don't usually write reviews, but I felt compelled to share my experience with Micro Inc SMP because of the exceptional quality of his work. The art of Scalp Micropigmentation (SMP) requires skill and finesse, and Chetan Mahajan from Micro Inc SMP is a Master. This procedure has been transformative. He gave me the confident I needed and a new look in life. He's such a perfectionist and took the time to do it right and make sure I'm happy with the result for anyone feeling stuck and looking for a change, I highly recommend Chetan’s Micro Inc SMP. Choosing Chetan Mahajan for your SMP treatment is a decision you won't regret. Forever grateful!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Clara Thomas"
                    text2="I dealt with hair loss as a woman for the past 3 years. I wasn't confident about how I looked. I tried almost every possible treatment to improve the condition but I wasn't happy. I was nervous at the start of the process but Chetan walked me through, explaining what he will be doing and made me comfortable. It was a painless process. Now my confidence is back and I can style and wear my hair in any way I want too. I can't thank Micro Inc. SMP enough for helping me bring this change in my life."
                    text3={SMP_FOR_WOMEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforwomen/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="George Fernandes"
                    text2="I recently had an exceptional experience at Micro Inc. SMP! The expertise at Micro Inc. SMP shone through as they patiently addressed all my queries, instilling in me a sense of confidence about the procedure. Under the skilled hands of Chetan Mahajan, I achieved natural-looking results that have significantly bolstered my self-assurance. Moreover, the minimal post-procedure care required is a definite advantage. I wholeheartedly recommend Micro Inc. for those seeking a non-surgical hair loss solution – it truly transformed my life! Special thanks to Chetan Mahajan for his outstanding work! As someone who sought hair treatment, I can confidently say that it's worth every penny."
                    text3={SMP_FOR_MEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpformen/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Uday Karkera"
                    text2="Few years back, I was suggested to opt for a patch from a repute clinic as I lacked sufficient donor area for a hair transplant, a suggestion I didn't pursue. Recently, a close friend recommended I explore Micro Inc. SMP for SMP. There, I met Chetan Mahajan, the Picasso I would say, in his Mahim studio. The procedure itself was painless, although each session required a few hours at a stretch. Chetan's skills and crafty hands ensured my comfort throughout. This innovation is truly remarkable, offering individuals with sparse hair the opportunity to transform their appearance and regain their confidence. Now, I confidently affirm that 50 is the new 40 :)"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Ronak Motta"
                    text2="I recently underwent the scalp micropigmentation procedure, and I couldn't be more thrilled with the results. As someone who has struggled with hair loss for years and have had a bald scalp, SMP has been a game-changer for me. From the initial consultation to the final session, the entire experience at Micro Inc. SMP was exceptional. The results exceeded my expectations. The skilled practitioner (Chetan Mahajan) meticulously created a natural-looking hairline that seamlessly blends with my existing hair. It's amazing how the treatment has significantly improved my confidence and overall appearance. “Yay I have a Hairline now”"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
            </div>
            <button style={{
                              'position': 'absolute',
                              'top': '50%',
                              'transform': 'translateY(-50%)',
                              'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                              'color': 'white',
                              'border': 'none',
                              'padding': '10px 10px 10px 5px',
                              'borderRadius': '50%',
                              'width': '40px',
                              'height': '80px',
                              'borderRadius': '0px 45px 45px 0',
                              'cursor': 'pointer',
                              'zIndex': '1',
                              'display': 'flex',
                              'alignItems': 'center',
                              'justifyContent': 'center',
                              'left': '0px',
                          }} onClick={() => scrollLeft()}>
                          <FaChevronLeft size={25} />
                        </button>
                        <button style={{
                              'position': 'absolute',
                              'top': '50%',
                              'transform': 'translateY(-50%)',
                              'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                              'color': 'white',
                              'border': 'none',
                              'padding': '10px 5px 10px 10px',
                              'borderRadius': '50%',
                              'width': '40px',
                              'height': '80px',
                              'borderRadius': '45px 0 0 45px',
                              'cursor': 'pointer',
                              'zIndex': '1',
                              'display': 'flex',
                              'alignItems': 'center',
                              'justifyContent': 'center',
                              'right': '0px',
                          }} onClick={() => scrollRight()}>
                          <FaChevronRight size={25} />
                        </button>
            </div>
        </div>
    );
}

function TestimonialCard({ text1, text2, text3, image1, image2, onClickAction }) {
    return (
        <div className='testimonial-home-card-component-container' >
            <div className='testimonial-home-card-component-in'>
                <div className='testimonial-home-card-component-content-area'>
                    <div className='testimonial-home-card-component-text0'>
                        {text1}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <MdOutlineStar style={{ color: '#e9be47' }} />
                            <MdOutlineStar style={{ color: '#e9be47' }} />
                            <MdOutlineStar style={{ color: '#e9be47' }} />
                            <MdOutlineStar style={{ color: '#e9be47' }} />
                            <MdOutlineStar style={{ color: '#e9be47' }} />
                        </div>
                    </div>
                    <div className='testimonial-home-card-component-text1'>
                        "{text2}"
                    </div>

                </div>
            </div>
        </div>
    );
}

export default TestimonialsHome;