import React, { useState, useRef } from 'react';
import '../styles/HomePage/HomePage.css';
import WhyNumber1 from '../components/HomePage/WhyNumber1';
import ConsultationForm from '../components/HomePage/ConsultationForm';
import Faq from '../components/HomePage/Faq';
import Footer from '../components/HomePage/Footer';
import About from '../components/HomePage/About';
import Navbar from '../components/HomePage/Navbar';
import Home from '../components/HomePage/Home';
import Treatments from '../components/HomePage/Treatments';
import CompareChart from '../components/HomePage/CompareChart';
import { useSelector, useDispatch } from 'react-redux';
import { selectShowDialog, setShowDialog } from '../state/slices'
import { selectDialogTitle, setDialogTitle } from '../state/slices'
import { selectDialogDesc, setDialogDesc } from '../state/slices'
import { selectShowLoading } from '../state/slices'
import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton';
import TestimonialsHome from '../components/TestimonialsHome';
import Highlights from '../components/Highlights';
import HighlightsOverlay from '../components/HighlightsOverlay';
import Spacer from '../components/Spacer';

function HomePage() {
  const showDialog = useSelector(selectShowDialog);
  const showLoading = useSelector(selectShowLoading);
  const dialogTitle = useSelector(selectDialogTitle);
  const dialogDesc = useSelector(selectDialogDesc);
  const dispatch = useDispatch();

  const [showOverlay, setShowOverlay] = useState(false);
  const [videoId, setVideoId] = useState("");

  function showTheOverlay(videoId) {
      setVideoId((value)=> videoId);
      setShowOverlay((prev) => true);
  }

  function hideTheOverlay() {
      setShowOverlay((prev) => false);
  }

  return (
    <div style={{ backgroundColor: "#fafafa", scrollbarWidth: 'none' }}>
      <div className='space-between-navbar'></div>
      <Home></Home>
      <Spacer></Spacer>
      <Treatments></Treatments>
      <Spacer></Spacer>
      <ConsultationForm></ConsultationForm>
      <Spacer></Spacer>
      <WhyNumber1></WhyNumber1>
      <Spacer></Spacer>
      <TestimonialsHome></TestimonialsHome>
      <Spacer></Spacer>
      <Highlights showTheOverlay={showTheOverlay}></Highlights>
      <Spacer></Spacer>
      <CompareChart></CompareChart>
      <Spacer></Spacer>
      <About></About>
      <Spacer></Spacer>
      <Faq></Faq>
      <Spacer></Spacer>
      <Footer></Footer>
      <FloatingWhatsAppButton></FloatingWhatsAppButton>
      <Navbar></Navbar>

      {showLoading && (<div className="dialog-overlay">
        <div className="loading">
          <div className="spinner"></div>
        </div>
      </div>)}

      {showDialog && (<div className="dialog-overlay">
        <div className="dialog">
          <p style={{ fontWeight: 'bold' }}>{dialogTitle}</p>
          <p>{dialogDesc}</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <button style={{ backgroundColor: '#023273', color: 'white' }} onClick={() => {
              dispatch(setShowDialog(false));
            }}>Ok</button>
          </div>
        </div>
      </div>)}

      {showOverlay && (<HighlightsOverlay hideTheOverlay={hideTheOverlay} videoId={videoId}></HighlightsOverlay>)}
    </div>
  );
}

export default HomePage;
