import React from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';

function Spacer() {
    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }
    
    return (
        <div style={reValue({
            desktop: {'height': '80px'},
            tablet: {'height': '80px'},
            mobile: {'height': '50px'},
        })}></div>
    );
}

export default Spacer;