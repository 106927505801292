import '../../styles/HomePage/WhyNumber1.css';
import { BULLET_NUMBER_1, BULLET_NUMBER_1_TEXT, BULLET_NUMBER_2, BULLET_NUMBER_2_TEXT, BULLET_NUMBER_3, BULLET_NUMBER_3_TEXT, HOME_VIDEO, WCU_BUTTON_TEXT, WCU_TITLE } from '../../constants';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function WhyNumber1() {
  const { width } = useWindowDimensions();
  return (
    <div className="why-number-1-section">
      <div className='inner-row'>
        <VideoComponent></VideoComponent>
        <div className='number-1-inner-column'>
          <div className='number-1-text'>
            {WCU_TITLE}
          </div>
          <BulletPoint
            number={BULLET_NUMBER_1}
            text={BULLET_NUMBER_1_TEXT}
          ></BulletPoint>
          <DottedLine></DottedLine>
          <BulletPoint
            number={BULLET_NUMBER_2}
            text={BULLET_NUMBER_2_TEXT}
          ></BulletPoint>
          <DottedLine></DottedLine>
          <BulletPoint
            number={BULLET_NUMBER_3}
            text={BULLET_NUMBER_3_TEXT}
          ></BulletPoint>
          <button className="quoteButton" onClick={() => {
            // if (width >= 1025) {
            //   // window.scrollTo(0, 3800);
            //   window.scrollTo({
            //     top: 1650,
            //     behavior: 'smooth'
            //   });
            //   return;
            // }
            // if (width <= 1024 && width > 766) {
            //   // window.scrollTo(0, 5250);
            //   window.scrollTo({
            //     top: 1650,
            //     behavior: 'smooth'
            //   });
            //   return;
            // }
            // if (width <= 766) {
            //   // window.scrollTo(0, 6620);
            //   window.scrollTo({
            //     top: 1580,
            //     behavior: 'smooth'
            //   });
            //   return;
            // }
            const element = document.querySelector(".consultation-form-main-column");
            // if (element) {
            //   element.scrollIntoView({ behavior: "smooth", block: "start" });
            // }
            if (element) {
                const navbarHeight = 150; // Adjust according to your navbar height
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({ top: elementPosition - navbarHeight, behavior: "smooth" });
            }
          }}>
            {WCU_BUTTON_TEXT}
          </button>
        </div>
      </div>
    </div>
  );
}

function BulletPoint({ number, text }) {
  return (
    <div className='number-bullet-row'>
      <div className='num-bullet-number'>{number}</div>
      <div className='num-bullet-text'>{text}</div>
    </div>
  );
}


function DottedLine() {
  return (
    <div className='dlr'>
      <div className='dotted-line-wn'></div>
    </div>
  );
}

function VideoComponent() {
  return (
    <div className='video-container'>
      <div className='video-component'
        dangerouslySetInnerHTML={{
          __html: `
                    <video autoplay loop muted playsinline style="width: 100%; height: 100%; object-fit: cover;">
                        <source src=${HOME_VIDEO} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>`,
        }}
      />
      {/* <video className='video-component' autoPlay muted loop>
        <source src={HOME_VIDEO} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <div className='video-component-overlay'>
      </div>
    </div>
  );
}

export default WhyNumber1;
