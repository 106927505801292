import React, { useState, useEffect, useRef } from 'react';
import '../styles/BlogPage/BlogPage.css';
import Footer from '../components/HomePage/Footer';
import Navbar from '../components/HomePage/Navbar';
import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { FaFacebook, FaInstagram, FaYoutube, FaTiktok, FaWhatsapp } from 'react-icons/fa';
import { CONTACT_LINK_FACEBOOK, CONTACT_LINK_INSTAGRAM, CONTACT_LINK_WHATSAPP } from '../constants';
import { margin } from '@mui/system';
import { AiOutlineClose } from 'react-icons/ai';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function HighlightsPage() {
    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    const [showOverlay, setShowOverlay] = useState(false);
    const [videoId, setVideoId] = useState("");


    function showTheOverlay(videoId) {
        setVideoId((value)=> videoId);
        setShowOverlay((prev) => true);
    }

    function hideTheOverlay() {
        setShowOverlay((prev) => false);
    }

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }
    return (
        <div style={{ backgroundColor: "#fafafa", scrollbarWidth: 'none', position: "relative" }}>
            {/* Transparent Black Overlay */}
            {showOverlay && (
                <div style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    zIndex: 999,
                    pointerEvents: "auto", // Ensures overlay captures clicks
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }} onClick={hideTheOverlay}>
                            {/* Close button */}
                    <button 
                        onClick={(e) => { 
                            e.stopPropagation(); // Prevents overlay click from closing
                            // toggleOverlay();
                            hideTheOverlay();
                        }}
                        style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            background: "transparent",
                            border: "none",
                            fontSize: "24px",
                            color: "black",
                            cursor: "pointer",
                            zIndex: 1000, // Makes sure the close button stays above everything
                        }}
                    >
                        <AiOutlineClose size={30} /> {/* React Icon for close */}
                    </button>
                    <div style={reValue({
                        desktop: { position: "relative", width: "300px", height: "535px", background: "#fff", borderRadius: "10px", overflow: "hidden" },
                        tablet: { position: "relative", width: "40%", height: "80%", background: "#fff", borderRadius: "10px", overflow: "hidden" },
                        mobile: { position: "relative", width: "68%", height: "80%", background: "#fff", borderRadius: "10px", overflow: "hidden" }
                    })} onClick={(e) => e.stopPropagation()}>
                            <iframe
                                width="100%"
                                height="100%"
                                src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=0&controls=0&rel=0`}
                                title="YouTube Short"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
            )}

            <div style={reValue({
                desktop: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '60px 0px 0px 0px', },
                tablet: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '160px 0px 0px 0px' },
                mobile: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '160px 0px 0px 0px' },
            })}>
                <HighlightsMainContainer showTheOverlay={showTheOverlay} />
            </div>

            <Footer />
            <FloatingWhatsAppButton />
            <Navbar />

            {/* Toggle Overlay Button */}
            {/* <button onClick={hideTheOverlay} style={{
                position: "fixed",
                bottom: 20,
                right: 20,
                zIndex: 1000,
                padding: "10px 15px",
                background: "#333",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer"
            }}>
                Toggle Overlay
            </button> */}
        </div>
    );
}

function HighlightsMainContainer({showTheOverlay}) {
    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }
    return (
        <div style={reValue({
            desktop: { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 'fitContent', width: '100vw', margin: '0px 0px 100px 0px' },
            tablet: { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 'fit-content', width: '100vw', margin: '0px 0px 100px 0px' },
            mobile: { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 'fit-content', width: '100vw', margin: '0px 0px 100px 0px' },
        })}>
            <div style={reValue({
                desktop: { display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', width: '90%' },
                tablet: { display: 'flex', flexDirection: 'column', height: 'fit-content', width: '80%' },
                mobile: { display: 'flex', flexDirection: 'column', height: 'fit-content', width: '90%' },
            })}>
                <HighlightsMainHeading></HighlightsMainHeading>
                <DottedLine></DottedLine>
                <CardsGrid showTheOverlay={showTheOverlay}></CardsGrid>
            </div>
        </div>
    );
}

function HighlightsMainHeading({showTheOverlay}) {
    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }
    return (
        <div style={reValue({
            desktop: { width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '100px 0px 60px 0px' },
            tablet: { width: '100%', display: 'flex', flexDirection: 'column', margin: '0px 0px 20px 0px' },
            mobile: { width: '100%', display: 'flex', flexDirection: 'column', margin: '0px 0px 20px 0px' },
        })}>
            <div style={reValue({
                desktop: { fontSize: '73px', fontWeight: 'bold', color: '#023273' },
                tablet: { fontSize: '48px', fontWeight: 'bold', color: '#023273' },
                mobile: { fontSize: '33px', fontWeight: 'bold', color: '#023273' }
            })}>
                SMP Stories
            </div>
            {/* <div className='fp-faq-small-text'>
                If the answer to your question is not listed below, please email us at info@microincsmp.com and we will get back to you as soon as we can!
            </div> */}
        </div>
    );
}

function DottedLine() {
    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }
    return (
        <div style={reValue({
            desktop: { display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', height: 'fit-content', width: '100%' },
            tablet: { display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', height: 'fit-content', width: '100%', margin: '0px 0px 40px 0px'  },
            mobile: { display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', height: 'fit-content', width: '100%', margin: '0px 0px 40px 0px' }
        })}>
            <div className='fp-dotted-line'></div>
        </div>
    );
}

function CardsGrid({showTheOverlay}) {
    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    const scrollRef = useRef(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            const { scrollLeft } = scrollRef.current;
            if (scrollLeft > 0) {  // Only scroll if not already at the start
                scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
            }
        }
    };
    
    const scrollRight = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            const maxScroll = scrollWidth - clientWidth;
    
            if (scrollLeft < maxScroll) {  // Only scroll if not already at the end
                scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
            }
        }
    };

    const cards = [
        { id: 0, title: 'Short 0', description: 'Description for Short 0', videoId: 'qR68TQNqUiQ', videoUrl: 'https://www.youtube.com/shorts/qR68TQNqUiQ' },
        { id: 1, title: 'Short 1', description: 'Description for Short 1', videoId: 'FVnf_QcsUdA', videoUrl: 'https://www.youtube.com/shorts/FVnf_QcsUdA' },
        { id: 2, title: 'Short 2', description: 'Description for Short 2', videoId: 'NxXENH1r1Xc', videoUrl: 'https://www.youtube.com/shorts/NxXENH1r1Xc' },
        { id: 3, title: 'Short 3', description: 'Description for Short 3', videoId: 'ylh-LuFJGH0', videoUrl: 'https://www.youtube.com/shorts/ylh-LuFJGH0' },
        { id: 4, title: 'Short 4', description: 'Description for Short 4', videoId: '5RzEcanl7CY', videoUrl: 'https://www.youtube.com/shorts/5RzEcanl7CY' },
        { id: 5, title: 'Short 5', description: 'Description for Short 5', videoId: 'WberSr7Fuxo', videoUrl: 'https://www.youtube.com/shorts/WberSr7Fuxo' },
        { id: 6, title: 'Short 6', description: 'Description for Short 6', videoId: 'iTx8AG3xehQ', videoUrl: 'https://www.youtube.com/shorts/iTx8AG3xehQ' },
        { id: 7, title: 'Short 7', description: 'Description for Short 7', videoId: 'KMUsBQAmovI', videoUrl: 'https://www.youtube.com/shorts/KMUsBQAmovI' },
        { id: 8, title: 'Short 8', description: 'Description for Short 8', videoId: 'f_zUE9E3Vgs', videoUrl: 'https://www.youtube.com/shorts/f_zUE9E3Vgs' },
        { id: 9, title: 'Short 9', description: 'Description for Short 9', videoId: '5sTzqI9Iyfg', videoUrl: 'https://www.youtube.com/shorts/5sTzqI9Iyfg' },
        { id: 10, title: 'Short 10', description: 'Description for Short 10', videoId: 'J7JEw9q0ZM8', videoUrl: 'https://www.youtube.com/shorts/J7JEw9q0ZM8' },
        { id: 11, title: 'Short 11', description: 'Description for Short 11', videoId: 'eDAP3VPnCTY', videoUrl: 'https://www.youtube.com/shorts/eDAP3VPnCTY' },
        { id: 12, title: 'Short 12', description: 'Description for Short 12', videoId: 'VBh0VDuU9wE', videoUrl: 'https://www.youtube.com/shorts/VBh0VDuU9wE' },
        { id: 13, title: 'Short 13', description: 'Description for Short 13', videoId: 'yBovl5XcxVg', videoUrl: 'https://www.youtube.com/shorts/yBovl5XcxVg' },
        { id: 14, title: 'Short 14', description: 'Description for Short 14', videoId: 'C_8AXRz8nJE', videoUrl: 'https://www.youtube.com/shorts/C_8AXRz8nJE' },
        { id: 14, title: 'Short 15', description: 'Description for Short 15', videoId: 'Ugq3-U3Jxfg', videoUrl: 'https://www.youtube.com/shorts/Ugq3-U3Jxfg' },
    ];

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }

    // State to handle hover effect
    const [hoveredCard, setHoveredCard] = useState(null);

    return (
        <div style={reValue({
            desktop: { 
                width: "100%",
                position: "relative", 
                display: "flex",
            },
            tablet: { 
                width: "100%",
                position: "relative", 
                display: "flex",
            },
            mobile: { 
                width: "100%",
                position: "relative", 
                display: "flex",
            },
        })}>
            <div ref={scrollRef} style={reValue({
                desktop: { 
                    display: 'grid', 
                    gridTemplateColumns: 'repeat(4, 1fr)', 
                    gap: '20px', 
                    margin: '50px 0', 
                    maxWidth: '1200px', // Set max width for desktop
                    marginLeft: 'auto',
                    marginRight: 'auto',
                },
                tablet: { 
                    width: '100%', 
                    overflowX: 'auto', 
                    whiteSpace: 'nowrap', 
                    scrollbarWidth: 'none' 
                },
                mobile: { 
                    width: '100%', 
                    overflowX: 'auto', 
                    whiteSpace: 'nowrap', 
                    scrollbarWidth: 'none' 
                },
            })}>
                {cards.map((card) => (
                    <div 
                        key={card.id} 
                        style={reValue({
                            desktop: {
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                display: 'inline-block',
                                flexDirection: 'column',
                                height: '510px',
                                width: 'auto',
                                justifyContent: 'flex-start',
                                maxWidth: '300px',
                                margin: '0 auto',
                                position: 'relative',
                                overflow: 'hidden', // Prevent overlay from going out of bounds
                                transition: '0.3s', // Smooth transition for hover
                                cursor: 'pointer'
                            },
                            tablet: {
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                display: 'inline-block',
                                flexDirection: 'column',
                                height: '540px',
                                width: 'auto',
                                justifyContent: 'flex-start',
                                maxWidth: '300px',
                                margin: '0px 10px 0px 0px',
                                position: 'relative',
                                overflow: 'hidden',
                                cursor: 'pointer'
                            },
                            mobile: {
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                display: 'inline-block',
                                flexDirection: 'column',
                                height: '80vw',
                                width: 'auto',
                                justifyContent: 'flex-start',
                                maxWidth: '48.5%',
                                margin: '0px 10px 0px 0px',
                                position: 'relative',
                                overflow: 'hidden',
                                cursor: 'pointer'
                            }
                        })}
                        // onMouseEnter={() => setHoveredCard(card.id)}
                        // onMouseLeave={() => setHoveredCard(null)}
                        onClick={ () => showTheOverlay(card.videoId)}
                    >
                        <img 
                            width="100%" 
                            height="100%"
                            src={`https://img.youtube.com/vi/${card.videoId}/0.jpg`}
                            alt={`Thumbnail for ${card.title}`}
                            style={{
                                objectFit: 'cover',
                                padding: '0',
                                margin: '0',
                                borderRadius: '8px',
                            }}
                        />
                        <div 
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: hoveredCard === card.id ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0)',
                                transition: 'background-color 0.3s ease-in-out',
                                borderRadius: '8px',
                            }}
                        />
                    </div>
                ))}
            </div>
            {
                reValue({
                    desktop: <button style={{
                        'position': 'absolute',
                        'top': '50%',
                        'transform': 'translateY(-50%)',
                        'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                        'color': 'white',
                        'border': 'none',
                        'padding': '10px 10px 10px 5px',
                        'borderRadius': '50%',
                        'width': '40px',
                        'height': '80px',
                        'borderRadius': '0px 45px 45px 0',
                        'cursor': 'pointer',
                        'zIndex': '1',
                        'display': 'none',
                        'alignItems': 'center',
                        'justifyContent': 'center',
                        'left': '0px',
                    }} onClick={() => scrollLeft()}> <FaChevronLeft size={25} />
                    </button>,
                    tablet: <button style={{
                        'position': 'absolute',
                        'top': '50%',
                        'transform': 'translateY(-50%)',
                        'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                        'color': 'white',
                        'border': 'none',
                        'padding': '10px 10px 10px 5px',
                        'borderRadius': '50%',
                        'width': '40px',
                        'height': '80px',
                        'borderRadius': '0px 45px 45px 0',
                        'cursor': 'pointer',
                        'zIndex': '1',
                        'display': 'flex',
                        'alignItems': 'center',
                        'justifyContent': 'center',
                        'left': '0px',
                    }} onClick={() => scrollLeft()}> <FaChevronLeft size={25} />
                    </button>,
                    mobile: <button style={{
                        'position': 'absolute',
                        'top': '50%',
                        'transform': 'translateY(-50%)',
                        'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                        'color': 'white',
                        'border': 'none',
                        'padding': '10px 10px 10px 5px',
                        'borderRadius': '50%',
                        'width': '40px',
                        'height': '80px',
                        'borderRadius': '0px 45px 45px 0',
                        'cursor': 'pointer',
                        'zIndex': '1',
                        'display': 'flex',
                        'alignItems': 'center',
                        'justifyContent': 'center',
                        'left': '0px',
                    }} onClick={() => scrollLeft()}> <FaChevronLeft size={25} />
                    </button>
                })
            }

            {
                reValue({
                    desktop: <button style={{
                        'position': 'absolute',
                        'top': '50%',
                        'transform': 'translateY(-50%)',
                        'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                        'color': 'white',
                        'border': 'none',
                        'padding': '10px 5px 10px 10px',
                        'borderRadius': '50%',
                        'width': '40px',
                        'height': '80px',
                        'borderRadius': '45px 0 0 45px',
                        'cursor': 'pointer',
                        'zIndex': '1',
                        'display': 'none',
                        'alignItems': 'center',
                        'justifyContent': 'center',
                        'right': '0px',
                    }} onClick={() => scrollRight()}>
                    <FaChevronRight size={25} />
                    </button>,
                    tablet: <button style={{
                        'position': 'absolute',
                        'top': '50%',
                        'transform': 'translateY(-50%)',
                        'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                        'color': 'white',
                        'border': 'none',
                        'padding': '10px 5px 10px 10px',
                        'borderRadius': '50%',
                        'width': '40px',
                        'height': '80px',
                        'borderRadius': '45px 0 0 45px',
                        'cursor': 'pointer',
                        'zIndex': '1',
                        'display': 'flex',
                        'alignItems': 'center',
                        'justifyContent': 'center',
                        'right': '0px',
                    }} onClick={() => scrollRight()}>
                    <FaChevronRight size={25} />
                    </button>,
                    mobile: <button style={{
                        'position': 'absolute',
                        'top': '50%',
                        'transform': 'translateY(-50%)',
                        'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                        'color': 'white',
                        'border': 'none',
                        'padding': '10px 5px 10px 10px',
                        'borderRadius': '50%',
                        'width': '40px',
                        'height': '80px',
                        'borderRadius': '45px 0 0 45px',
                        'cursor': 'pointer',
                        'zIndex': '1',
                        'display': 'flex',
                        'alignItems': 'center',
                        'justifyContent': 'center',
                        'right': '0px',
                    }} onClick={() => scrollRight()}>
                    <FaChevronRight size={25} />
                    </button>
                })
            }
            
            
        </div>
    );
}

export default HighlightsPage;