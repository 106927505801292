import React from 'react';
import '../../styles/SmpForScarsPage/TreatmentsForScars.css';
import { SMP_FOR_SCARS_URL_1, SMP_FOR_SCARS_URL_2 } from '../../constants';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { display } from '@mui/system';


function TreatmentsForScars() {
    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }

    return (
        <div style={reValue({
            desktop: { display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'center' },
            tablet: { display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'center' },
            mobile: { display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'center' },
        })}>
            <div style={reValue({
                desktop: { width: '90%' },
                tablet: { width: '80%' },
                mobile: { width: '90%' },
            })}>
                <div style={reValue({
                    desktop: { float: 'right', margin: '0px 0px 0px 20px' },
                    tablet: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
                    mobile: { display: 'flex', flexDirection: 'column' }
                })}>
                    <img style={reValue({
                        desktop: { height: '330px', width: '250px', margin: '0px 10px 0px 0px' },
                        tablet: { height: '50vw', width: '48.5%', margin: '0px 0px 0px 0px' },
                        mobile: { height: '100vw', width: '100%', margin: '0px 0px 0px 0px' },
                    })}
                        src={SMP_FOR_SCARS_URL_1} alt="Scalp Micropigmentation"
                    />
                    <img style={reValue({
                        desktop: { height: '330px', width: '250px', margin: '0px 0px 0px 10px' },
                        tablet: { height: '50vw', width: '48.5%', margin: '0px 0px 0px 0px' },
                        mobile: { height: '100vw', width: '100%', margin: '20px 0px 0px 0px' },
                    })}
                        src={SMP_FOR_SCARS_URL_2} alt="Scalp Micropigmentation"
                    />
                </div>
                <div>
                    <p style={reValue({
                        desktop: { textAlign: 'justify', fontSize: '15px', margin: '0px 0px 0px 0px', lineHeight: '25px' },
                        tablet: { textAlign: 'justify', fontSize: '15px', margin: '40px 0px 0px 0px', lineHeight: '25px' },
                        mobile: { textAlign: 'justify', fontSize: '15px', margin: '20px 0px 0px 0px', lineHeight: '25px' },
                    })}>
                        {/* Follicular Unit Extraction (FUE) and Follicular Unit Transplant (FUT) are both popular methods of hair transplantation, yet they can result in scarring, which may concern some clients. */}
                        Follicular Unit Extraction (FUE) and Follicular Unit Transplant (FUT) are popular hair transplant methods, but they can sometimes leave scars that might be a concern to some of you. While covering these scars can be complex, techniques like dry needling and multiple treatments can help. Additionally, scalp micropigmentation (SMP) can effectively camouflage FUE and FUT scars to a reasonable extent, making them less noticeable. The number of SMP sessions needed will vary based on the size and type of the scar.
                    </p>
                    {/* <p style={{ textAlign: 'justify', fontSize: '15px', margin: '20px 0px 0px 0px', lineHeight: '25px' }}>
                        While addressing these scars is a more intricate process, techniques such as dry needling and repeated treatments can effectively disguise them. Furthermore, scars resulting from past injuries or surgeries may also contribute to dissatisfaction with one's appearance. In such cases, scalp micropigmentation offers a solution for concealing these scars.
                        The number of sessions required to cover a scar will vary depending on its size and characteristics.
                    </p> */}
                    <button className="quoteButtonHomeAlopecia" onClick={() => {
                        // window.scrollTo(reValue({
                        //     desktop: {
                        //         top: 1250,
                        //         behavior: 'smooth'
                        //     },
                        //     tablet: {
                        //         top: 1650,
                        //         behavior: 'smooth'
                        //     },
                        //     mobile: {
                        //         top: 2200,
                        //         behavior: 'smooth'
                        //     },
                        // }));
                        const element = document.querySelector(".consultation-form-main-column");
                        // if (element) {
                        //   element.scrollIntoView({ behavior: "smooth", block: "start" });
                        // }
                        if (element) {
                            const navbarHeight = 150; // Adjust according to your navbar height
                            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                            window.scrollTo({ top: elementPosition - navbarHeight, behavior: "smooth" });
                        }
                    }}>
                        GET A FREE CONSULTATION
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TreatmentsForScars;
