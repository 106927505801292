export const BRAND_LOGO = '/logo.jpg';

// Home.js
export const HOME_VIDEO = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fwebsite%20video%20cc.mp4?alt=media&token=4f97d3cc-1181-44f2-8d67-042a18746e9b';
export const HOME_VIDEO_HEADER_1 = 'Beat Hair Loss with';
export const HOME_VIDEO_HEADER_2 = 'Scalp Micropigmentation';
export const HOME_VIDEO_HEADER_3 = 'Scalp Micropigmentation is a highly effective, non-invasive, low maintenance solution to hair loss that delivers immediate, long term results.';
export const HOME_VIDEO_BUTTON_TEXT = 'GET A FREE CONSULTATION';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// About.js
export const FOUNDER_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Ffounder-image%2Ffounder-pic.JPG?alt=media&token=73281add-12f6-4eed-939f-235463815bc2';
export const CERTIFICATE_1 = "https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fcertificates%2FChetan%20Deepak%20Mahajan%20-%20LDRSMP%20Academy%20-%20Artist%20-%20Certificate%20Of%20Artist%20(Master%20Jay).jpg?alt=media&token=132276f4-a229-414c-bf3f-38f47b6b87a3";
export const CERTIFICATE_2 = "https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fcertificates%2FWhatsApp%20Image%202024-11-10%20at%2016.01.12.jpeg?alt=media&token=18d75584-579d-42d9-bb73-fb413908f887";
export const ABOUT_TITLE = 'Meet Your Artist';
// export const ABOUT_TEXT = 'Hey there, I\'m Chetan Mahajan, founder of Micro Inc. SMP. Dedicated to transforming lives through Scalp Micropigmentation (SMP), I blend artistry and technology to help individuals reclaim confidence and redefine self-image. My journey began with personal struggles with hair loss, leading me to discover SMP as a solution that not only addresses physical aspects but also restores inner confidence. Trained by Jay Grewal, founder of Elixir Pigmentation London, I combine expertise with empathy to guide clients through a journey of self-assurance and empowerment. Join me in empowering individuals, one scalp at a time, towards a future of boundless self-confidence.';
export const ABOUT_TEXT = 'Hello, I\'m Chetan Mahajan, the founder of Micro Inc. SMP and a certified SMP artist through Leaders Academy. My mission is to transform lives by combining the precision of technology with the art of Scalp Micropigmentation (SMP). My journey into SMP was born from my own experience with hair loss, leading me to discover a powerful solution that restores not just hairlines but also self-confidence. Under the mentorship of Jay Grewal, founder of Elixir Pigmentation London, I\'ve honed my skills to provide a compassionate and expert approach to every client\'s unique journey. Let\'s work together to help individuals reclaim their confidence, one scalp at a time.';
export const ABOUT_BUTTON_TEXT = 'Get in Touch';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// Treatments.js
export const TREATMENTS_HEADING_1 = 'TREATMENTS';
export const TREATMENTS_HEADING_2 = 'Overcoming hair loss is finally easy!';
//................................................................
// export const SMP_FOR_MEN_URL_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2FScreenshot%202024-06-02%20at%209.21.48%E2%80%AFPM.png?alt=media&token=c0b946c6-dc93-4286-84cc-ab3b1dfce48a';
// export const SMP_FOR_MEN_URL_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fsmp-for-men2.PNG?alt=media&token=54f6b8a6-e381-47ed-b2f4-ee980c9c8c67';
export const SMP_FOR_MEN_URL_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fdensity-enhancement%2FWhatsApp%20Image%202024-07-20%20at%2021.38.16.jpeg?alt=media&token=77ac7188-b600-4b9f-9b64-6260ee0dae71';
export const SMP_FOR_MEN_URL_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fdensity-enhancement%2FWhatsApp%20Image%202024-07-20%20at%2021.37.31.jpeg?alt=media&token=65a1c9ef-854b-439c-89d6-8ba4e4b274fa';
export const SMP_FOR_MEN_CARD_TITLE = 'SMP for Men';
export const SMP_FOR_MEN_CARD_DESCRIPTION = 'Achieve a sleek crew cut look, enhance hair density, or seamlessly combine hair transplant with SMP for a fuller appearance. Experience the confidence of a rejuvenated hairline with our tailored solutions.';
export const SMP_FOR_MEN_CARD_FOOTER = 'Explore SMP for Men';
//................................................................
// export const SMP_FOR_WOMEN_URL_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-women%2FScreenshot%202024-06-02%20at%209.40.56%E2%80%AFPM.png?alt=media&token=97760e83-b6e7-4934-bd2a-4a49b59ad5ec';
// export const SMP_FOR_WOMEN_URL_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-women%2FScreenshot%202024-06-02%20at%209.41.07%E2%80%AFPM.png?alt=media&token=e574cec6-23c6-409a-8e1c-6aec6d8dc51e';
export const SMP_FOR_WOMEN_URL_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-women%2Fhair_thinning%2FIMG_0543.PNG?alt=media&token=0976b885-48ec-4dbf-8244-90b188e1d79e';
export const SMP_FOR_WOMEN_URL_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-women%2Fhair_thinning%2FIMG_0544.PNG?alt=media&token=bfdaf4c9-bcd2-4c41-bae4-a3d0471a372f';
export const SMP_FOR_WOMEN_CARD_TITLE = 'SMP for Women';
export const SMP_FOR_WOMEN_CARD_DESCRIPTION = 'Enhance hair density and restore confidence with our specialized SMP techniques designed to address thinning hairlines and boost overall hair appearance. Rediscover your natural beauty with our personalized treatments.';
export const SMP_FOR_WOMEN_CARD_FOOTER = 'Discover SMP for Women';
//................................................................
export const SMP_FOR_ALOPECIA_URL_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-alopecia%2FUntitled%20design.zip%20-%203.png?alt=media&token=79d05c6a-79e1-4753-b3c6-4f2febfc9d50';
export const SMP_FOR_ALOPECIA_URL_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-alopecia%2FUntitled%20design.zip%20-%204.png?alt=media&token=9bdcb632-7828-4f17-a56f-0f996560027f';
export const SMP_FOR_ALOPECIA_CARD_TITLE = 'SMP for Alopecia';
export const SMP_FOR_ALOPECIA_CARD_DESCRIPTION = 'Combat the effects of Alopecia with our advanced SMP procedures tailored to your unique needs. Regain control over your appearance and embrace a renewed sense of confidence.';
export const SMP_FOR_ALOPECIA_CARD_FOOTER = 'Learn about SMP for Alopecia';
//................................................................
export const SMP_FOR_SCARS_URL_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-scars%2FUntitled%20design.zip%20-%205.png?alt=media&token=c236cdf1-956f-4b61-8317-9aabf4258439';
export const SMP_FOR_SCARS_URL_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-scars%2FUntitled%20design.zip%20-%206.png?alt=media&token=4986a77b-489c-485a-a348-eb73e5a7ae2c';
export const SMP_FOR_SCARS_CARD_TITLE = 'SMP for Scars';
export const SMP_FOR_SCARS_CARD_DESCRIPTION = 'Conceal scars and imperfections with our innovative SMP techniques, seamlessly blending them with your natural hairline. Restore both appearance and confidence with our personalized solutions.';
export const SMP_FOR_SCARS_CARD_FOOTER = 'Explore SMP for Scars';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// WhyNumber1.js
export const WCU_TITLE = 'Why Choose Us';
export const BULLET_NUMBER_1 = '01';
export const BULLET_NUMBER_1_TEXT = 'Our practitioner will give honest advice on whether SMP is right for you and recommend other hair loss solutions if needed.';
export const BULLET_NUMBER_2 = '02';
// export const BULLET_NUMBER_2_TEXT = 'We\'re known for our gold standard service, with the same practitioner guiding your experience from start to finish, setting us apart from other clinics.';
export const BULLET_NUMBER_2_TEXT = 'We\'re known for our exceptional service, with the same practitioner guiding your experience from start to finish, setting us apart from other clinics.';
export const BULLET_NUMBER_3 = '03';
export const BULLET_NUMBER_3_TEXT = 'We can offer flexible payment plans.';
export const WCU_BUTTON_TEXT = 'GET A FREE CONSULTATION';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// CompareChart.js
export const CC_HEADING_1 = 'COMPARE';
export const CC_HEADING_2 = 'MICRO INC. SMP vs other hair loss options';
//................................................................
export const UPPER_HEADING_1_IMAGE = '/bald-man.png';
export const UPPER_HEADING_1 = 'SMP';
//................................................................
export const UPPER_HEADING_2_IMAGE = '/hair-transplant.png';
export const UPPER_HEADING_2 = 'Hair Transplant';
//................................................................
export const UPPER_HEADING_3_IMAGE = '/hair-system.png';
export const UPPER_HEADING_3 = 'Hair System';
//................................................................
export const UPPER_HEADING_4_IMAGE = '/rogaine.png';
export const UPPER_HEADING_4 = 'Rogaine';
//................................................................
export const SIDE_HEADING_1 = 'Non surgical';
export const SIDE_HEADING_2 = 'Immediate Results';
export const SIDE_HEADING_3 = 'Low maintenance';
export const SIDE_HEADING_4 = 'Affordable';
export const SIDE_HEADING_5 = 'Looks realistic';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// ConsultationForm.js
export const CF_HEADING_1 = 'FREE CONSULTATION';
export const CF_HEADING_2 = 'Get your FREE Scalp Micropigmentation consultation';
export const CF_HEADING_3 = 'Speak with a scalp micropigmentation expert today!';
//................................................................
export const CF_RIGHT_TITLE = 'Types of Consultations';
//................................................................
export const CF_RIGHT_BULLET_NUMBER_1 = '1';
export const CF_RIGHT_BULLET_NUMBER_1_TEXT = 'In Person - Get all your questions answered face to face by the scalp micropigmentation artist.';
//................................................................
export const CF_RIGHT_BULLET_NUMBER_2 = '2';
export const CF_RIGHT_BULLET_NUMBER_2_TEXT = 'Phone - Speak directly with the SMP artist to get your questions answered.';
//................................................................
export const CF_RIGHT_BULLET_NUMBER_3 = '3';
export const CF_RIGHT_BULLET_NUMBER_3_TEXT = 'WhatsApp - Learn more about scalp micropigmentation process by a WhatsApp Chat or Video call.';
//................................................................
export const CF_RIGHT_BULLET_NUMBER_4 = '4';
export const CF_RIGHT_BULLET_NUMBER_4_TEXT = 'Receive a quote and treatment plan based on your specific hair loss goals';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// Faq.js
export const FAQ_HEADING_1 = 'FAQ';
export const FAQ_HEADING_2 = 'Questions about Scalp Micropigmentation';
export const FAQ_DESCRIPTION = 'The answers below will help teach you the basics about scalp micropigmentation. For further information, contact us with your questions or book a free consultation. This quick, non-surgical treatment is a great option for anyone looking for realistic, lasting results for their hair-loss struggles. Your expert practitioner will make tiny impressions onto the client’s skin in the desired location to replicate the look of small hair follicles. This treatment provides guaranteed results for clients no matter their skin tone, gender, or age. With the proper care and follow-up sessions, the scalp tattoo can last for 4-6 years before a touch-up is needed.';
//................................................................
export const FAQ_TILE_1_HEADING = 'What is scalp micropigmentation (SMP)?';
export const FAQ_TILE_1_DESCRIPTION = 'SMP is a non-invasive cosmetic procedure that involves the application of tiny pigment deposits to the scalp to replicate the appearance of hair follicles, creating the illusion of fuller hair or a closely-cropped hairstyle.';
//................................................................
export const FAQ_TILE_2_HEADING = 'Is SMP suitable for both men and women?';
export const FAQ_TILE_2_DESCRIPTION = 'Yes, SMP is suitable for both men and women experiencing various degrees of hair loss or thinning. It can also be used to camouflage scars or enhance the appearance of a receding hairline.';
//................................................................
export const FAQ_TILE_3_HEADING = 'How long does the SMP procedure take?';
export const FAQ_TILE_3_DESCRIPTION = 'The duration of the SMP procedure depends on the extent of the treatment area and the desired result. Typically, each session can last from 3 to 5 hours, and multiple sessions may be required to achieve optimal results.';
//................................................................
export const FAQ_TILE_4_HEADING = 'Is SMP painful?';
export const FAQ_TILE_4_DESCRIPTION = 'While we do not guarantee that the procedure will be entirely painless, some areas of the scalp, like the temples, may be more sensitive than others, such as the crown. However, most clients find the discomfort during SMP to be tolerable and manageable.';
//................................................................
export const FAQ_TILE_5_HEADING = 'What is the recovery time after SMP?';
export const FAQ_TILE_5_DESCRIPTION = 'There is minimal downtime associated with SMP, and most individuals can resume their regular activities immediately after the procedure. However, it is common to experience some redness or tenderness in the treated area, which typically subsides within a few days.';
//................................................................
//................................................................
export const FAQ_TILE_6_HEADING = 'How long do SMP results last?';
export const FAQ_TILE_6_DESCRIPTION = 'The longevity of SMP results can vary depending on factors such as skin type, lifestyle, and exposure to the sun. On average, SMP results can last between 3 to 5 years before requiring touch-up sessions to maintain the desired appearance.';
//................................................................
export const FAQ_TILE_7_HEADING = 'Are there any side effects of SMP?';
export const FAQ_TILE_7_DESCRIPTION = 'While SMP is considered safe, some potential side effects may include temporary redness, swelling, or irritation in the treated area. These side effects are typically mild and subside within a few days following the procedure.';
//................................................................
export const FAQ_TILE_8_HEADING = 'Can SMP be reversed or removed?';
export const FAQ_TILE_8_DESCRIPTION = 'SMP is considered a permanent cosmetic solution, but it can be adjusted or corrected if needed. Laser treatments or specialized removal techniques may be used to modify or remove SMP pigment if desired.';
//................................................................
export const FAQ_TILE_9_HEADING = 'How do I choose a qualified SMP practitioner?';
export const FAQ_TILE_9_DESCRIPTION = 'When considering SMP treatment, it\'s essential to research and select a reputable and experienced practitioner who specializes in scalp micropigmentation. Look for certifications, reviews, and before-and-after photos to ensure you\'re in capable hands.';
//................................................................
export const FAQ_TILE_10_HEADING = 'Is SMP covered by insurance?';
export const FAQ_TILE_10_DESCRIPTION = 'SMP is typically considered a cosmetic procedure and is not covered by insurance. However, we do provide payment plans to help make SMP more accessible for our clients.';
//................................................................
export const FAQ_BUTTON_TEXT = 'View All FAQ';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// Footer.js
export const FOOTER_NEWSLETTER_HEADING = 'NEWSLETTER';
export const FOOTER_EMAIL_FIELD_HEADING = 'Enter email to get in touch';
export const FOOTER_BUTTON_TEXT = 'Get a free consultation';
export const FOOTER_HASHTAG = '#MICROINCSMP';
//................................................................
export const FOOTER_TEXT_1 = 'ALL RIGTHS RESERVED ©MICRO INC SMP 2024';
export const FOOTER_TEXT_2 = 'PRIVACY POLICY';
export const FOOTER_TEXT_3 = 'TERMS & CONDITIONS';
//................................................................
// export const CONTACT_EMAIL = 'info@gmail.com';
export const CONTACT_EMAIL = 'info@microincsmp.com';
export const CONTACT_PHONE = '+91 982 001 1996';
//................................................................
export const CONTACT_LINK_FACEBOOK = 'https://www.facebook.com/profile.php?id=61558183721710';
export const CONTACT_LINK_INSTAGRAM = 'https://www.instagram.com/microinc.smp?igsh=MWNwbTF6Yzdqd2ZxaA==';
export const CONTACT_LINK_WHATSAPP = 'https://wa.me/+919820011996';
export const CONTACT_LINK_YOUTUBE = 'https://www.youtube.com/@microinc_smp';
export const CONTACT_LINK_TIKTOK = '';
//................................................................
export const FOOTER_TREATMENTS_HEADING = 'TREATMENTS';
export const FOOTER_TREATMENTS_OPTION_1 = 'SMP for Men';
export const FOOTER_TREATMENTS_OPTION_2 = 'SMP for Women';
export const FOOTER_TREATMENTS_OPTION_3 = 'SMP for Alopecia';
export const FOOTER_TREATMENTS_OPTION_4 = 'SMP for Scars';
export const FOOTER_TREATMENTS_OPTION_5 = 'Female Hair Loss';
export const FOOTER_TREATMENTS_OPTION_6 = 'Reviews';
export const FOOTER_TREATMENTS_OPTION_7 = 'Payment Plans';
//................................................................
export const FOOTER_LEARN_HEADING = 'LEARN';
export const FOOTER_LEARN_OPTION_1 = 'FAQ';
export const FOOTER_LEARN_OPTION_2 = 'Blog';
export const FOOTER_LEARN_OPTION_3 = 'FAQ';
export const FOOTER_LEARN_OPTION_4 = 'Blog';
export const FOOTER_LEARN_OPTION_5 = 'Press';
export const FOOTER_LEARN_OPTION_6 = 'Contact';
//................................................................
export const FOOTER_LOCATIONS_HEADING = 'LOCATIONS';
export const FOOTER_LOCATIONS_OPTION_1 = 'New York';
export const FOOTER_LOCATIONS_OPTION_2 = 'Los Angeles';
export const FOOTER_LOCATIONS_OPTION_3 = 'Scar camouflage';
export const FOOTER_LOCATIONS_OPTION_4 = 'Houston';
export const FOOTER_LOCATIONS_OPTION_5 = 'Chicago';
export const FOOTER_LOCATIONS_OPTION_6 = 'Reviews';
export const FOOTER_LOCATIONS_OPTION_7 = 'South Florida';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// SmpForMenPage.js
export const SMP_FOR_MEN_BANNER_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
//................................................................
export const SMP_FOR_MEN_CARD2_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fcrew-cut%2FIMG_0442.PNG?alt=media&token=7ca896c9-2145-4f75-93a1-f63d66aaca7e';
export const SMP_FOR_MEN_CARD2_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fcrew-cut%2FIMG_0443.PNG?alt=media&token=74132775-fed2-4da9-8b37-de1499594447';
//................................................................
// export const SMP_FOR_MEN_CARD2_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fdensity-enhancement%2FIMG_0440.PNG?alt=media&token=2ec2ae50-107a-4f78-a840-eb9458feba69';
// export const SMP_FOR_MEN_CARD2_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fdensity-enhancement%2FIMG_0439.PNG?alt=media&token=37ae5409-bab1-472c-b875-b60494815c20';
// export const SMP_FOR_MEN_CARD2_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fdensity-enhancement%2FScreenshot%202024-07-20%20at%208.50.35%E2%80%AFPM.png?alt=media&token=10efa742-1128-4302-91e7-0d808c677d96';
// export const SMP_FOR_MEN_CARD2_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fdensity-enhancement%2FScreenshot%202024-07-20%20at%208.49.31%E2%80%AFPM.png?alt=media&token=0f34e54a-64a6-47d4-97a9-a220d3490a4c';
// export const SMP_FOR_MEN_CARD2_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fdensity-enhancement%2FScreenshot%202024-07-20%20at%209.12.10%E2%80%AFPM.png?alt=media&token=f2017e50-f4db-43fd-bef5-af2df4963233';
// export const SMP_FOR_MEN_CARD2_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fdensity-enhancement%2FScreenshot%202024-07-20%20at%209.01.00%E2%80%AFPM.png?alt=media&token=d956e436-de89-4133-b939-6a008930103f';
export const SMP_FOR_MEN_CARD1_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fdensity-enhancement%2FWhatsApp%20Image%202024-07-20%20at%2021.38.16.jpeg?alt=media&token=77ac7188-b600-4b9f-9b64-6260ee0dae71';
export const SMP_FOR_MEN_CARD1_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fdensity-enhancement%2FWhatsApp%20Image%202024-07-20%20at%2021.37.31.jpeg?alt=media&token=65a1c9ef-854b-439c-89d6-8ba4e4b274fa';
//................................................................
export const SMP_FOR_MEN_CARD3_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fcombine-hair-transplant%2FIMG_7789.PNG?alt=media&token=5f2aa061-e384-42e0-b79d-ad6840f69335';
export const SMP_FOR_MEN_CARD3_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fcombine-hair-transplant%2FCombine%20Hair%20Transplant%20with%20Scalp%20Micropigmentation%20.PNG?alt=media&token=8fe14033-5cab-4b31-aa6b-f217e4a857ec';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// SmpForWomen.js
export const SMP_FOR_WOMEN_BANNER_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
//................................................................
export const SMP_FOR_WOMEN_CARD1_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-women%2Fdensity_enhancement%2FUntitled%20design.zip%20-%201.png?alt=media&token=7292f90d-792d-49ba-8e90-c9d57d1ce876';
export const SMP_FOR_WOMEN_CARD1_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-women%2Fdensity_enhancement%2FUntitled%20design.zip%20-%202.png?alt=media&token=d6cf0ace-f590-4d3f-96a5-bbf8e17e3d4d';
//................................................................
export const SMP_FOR_WOMEN_CARD2_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-women%2Fdensity_enhancement%2FUntitled%20design.zip%20-%201.png?alt=media&token=7292f90d-792d-49ba-8e90-c9d57d1ce876';
export const SMP_FOR_WOMEN_CARD2_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-women%2Fdensity_enhancement%2FUntitled%20design.zip%20-%202.png?alt=media&token=d6cf0ace-f590-4d3f-96a5-bbf8e17e3d4d';
//................................................................
export const SMP_FOR_WOMEN_CARD3_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-women%2Fhair_thinning%2FIMG_0543.PNG?alt=media&token=0976b885-48ec-4dbf-8244-90b188e1d79e';
export const SMP_FOR_WOMEN_CARD3_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-women%2Fhair_thinning%2FIMG_0544.PNG?alt=media&token=bfdaf4c9-bcd2-4c41-bae4-a3d0471a372f';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// SmpForAlopecia.js
export const SMP_FOR_ALOPECIA_BANNER_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
//................................................................
export const SMP_FOR_ALOPECIA_CARD1_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
export const SMP_FOR_ALOPECIA_CARD1_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
//................................................................
export const SMP_FOR_ALOPECIA_CARD2_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
export const SMP_FOR_ALOPECIA_CARD2_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
//................................................................
export const SMP_FOR_ALOPECIA_CARD3_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
export const SMP_FOR_ALOPECIA_CARD3_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// SmpForScars.js
export const SMP_FOR_SCARS_BANNER_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
//................................................................
export const SMP_FOR_SCARS_CARD1_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
export const SMP_FOR_SCARS_CARD1_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
//................................................................
export const SMP_FOR_SCARS_CARD2_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
export const SMP_FOR_SCARS_CARD2_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
//................................................................
export const SMP_FOR_SCARS_CARD3_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
export const SMP_FOR_SCARS_CARD3_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
