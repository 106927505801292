import React, { useRef } from 'react';
import '../../styles/SmpForMenPage/TreatmentsForMen.css';
import { BiChevronLeftCircle, BiChevronRightCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { SMP_FOR_MEN_CARD1_IMAGE_1, SMP_FOR_MEN_CARD1_IMAGE_2, SMP_FOR_MEN_CARD2_IMAGE_1, SMP_FOR_MEN_CARD2_IMAGE_2, SMP_FOR_MEN_CARD3_IMAGE_1, SMP_FOR_MEN_CARD3_IMAGE_2 } from '../../constants';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function TreatmentsForMen() {
    return (
        <div>
            <TreatmentsForMenMainDesktop></TreatmentsForMenMainDesktop>
            <TreatmentsForMenMainTablet></TreatmentsForMenMainTablet>
            <TreatmentsForMenMainMobile></TreatmentsForMenMainMobile>
        </div>
    );
};

function TreatmentsForMenMainDesktop() {
    const navigate = useNavigate();
    const styles = {
        buttonContainer: {
            // backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItms: 'center',
            fontSize: '30px',
            margin: '0px 5px 0px 0px'
        },
        icon: {
            cursor: 'pointer',
            color: 'grey',
        },
    };

    const scrollRef = useRef(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            const { scrollLeft } = scrollRef.current;
            if (scrollLeft > 0) {  // Only scroll if not already at the start
                scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
            }
        }
    };
    
    const scrollRight = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            const maxScroll = scrollWidth - clientWidth;
    
            if (scrollLeft < maxScroll) {  // Only scroll if not already at the end
                scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
            }
        }
    };
    return (
        <div className='treatments-for-men-main-desktop'>
            <div className='treatments-for-men-head'>
                <div className='treatments-for-men-head-column'>
                    <div className='treatments-for-men-text'>
                        HOW IT WORKS
                    </div>
                    <div className='treatments-for-men-text2'>
                        Overcoming Hair Loss is Finally Easy!
                    </div>
                </div>
                <div style={styles.buttonContainer}>
                    <BiChevronLeftCircle onClick={scrollLeft} style={styles.icon} />
                    <div style={{ width: '15px' }}></div>
                    <BiChevronRightCircle onClick={scrollRight} style={styles.icon} />
                </div>
            </div>
            <div ref={scrollRef} className='treatment-for-men-cards'>
                <TreatmentCard
                    text1={"Crew cut"}
                    text2={"Our crew cut treatment at Micro Inc. SMP builds density over three sessions, each lasting a few hours with minimal downtime. Ideal for those with male pattern baldness, this technique creates a natural, shaved head look. We work closely with you to design your desired hairline through detailed consultations, ensuring the best outcome. For optimal results, avoid washing the treated area for 4 days post-session."}
                    text3={"Explore SMP for Men"}
                    image1={SMP_FOR_MEN_CARD1_IMAGE_1}
                    image2={SMP_FOR_MEN_CARD1_IMAGE_2}
                    onClickAction={() => {
                    }}
                ></TreatmentCard>
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={"Density enhancement"}
                    text2={"Scalp micropigmentation (SMP) effectively reduces the visibility of hair loss by creating the illusion of thicker hair. Suitable for both men and women, SMP delivers immediate results, blending seamlessly with your natural hairline. It can be combined with other hair loss treatments and doesn't hinder future hair transplants. Sessions, scheduled 7 to 10 days apart, typically last a few hours with minimal downtime."}
                    text3={"Discover SMP for Women"}
                    image1={SMP_FOR_MEN_CARD2_IMAGE_1}
                    image2={SMP_FOR_MEN_CARD2_IMAGE_2}
                    onClickAction={() => {
                    }}
                ></TreatmentCard>
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={"Combine Hair Transplant with Scalp Micropigmentation (SMP)"}
                    text2={"Combining hair transplant and scalp micropigmentation is increasingly popular. Some opt for SMP six months post-transplant to enhance density, while others choose SMP before a transplant for a gradual transformation or to reduce grafts needed. This combination effectively achieves a fuller head of hair, and SMP is a viable alternative for those unsatisfied with previous transplants."}
                    text3={"Learn about SMP for Alopecia"}
                    image1={SMP_FOR_MEN_CARD3_IMAGE_1}
                    image2={SMP_FOR_MEN_CARD3_IMAGE_2}
                    onClickAction={() => {
                    }}
                ></TreatmentCard>
                {/* <div className='tc-space'></div> */}
                {/* <TreatmentCard
                    text1={"SMP for scars"}
                    text2={"Conceal scars and imperfections with our innovative SMP techniques, seamlessly blending them with your natural hairline. Restore both appearance and confidence with our personalized solutions."}
                    text3={"Explore SMP for Scars"}
                    onClickAction={() => {
                        
                    }}
                ></TreatmentCard> */}
            </div>
        </div>
    );
}

function TreatmentsForMenMainTablet() {
    const navigate = useNavigate();
    const scrollRef = useRef(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            const { scrollLeft } = scrollRef.current;
            if (scrollLeft > 0) {  // Only scroll if not already at the start
                scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
            }
        }
    };
    
    const scrollRight = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            const maxScroll = scrollWidth - clientWidth;
    
            if (scrollLeft < maxScroll) {  // Only scroll if not already at the end
                scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
            }
        }
    };
    return (
        <div className='treatments-for-men-main-tablet'>
            <div className='treatments-for-men-head'>
                <div className='treatments-for-men-head-column'>
                    <div className='treatments-for-men-text'>
                        TREATMENTS
                    </div>
                    <div className='treatments-for-men-text2'>
                        Overcoming hair loss is finally easy!
                    </div>
                </div>
            </div>
            <div style={{ 
                width: "80%",
                position: "relative", 
                display: "flex",
            }}>
            <div ref={scrollRef} className='treatment-for-men-cards1'>
                <TreatmentCard
                    text1={"Crew cut"}
                    text2={"Our crew cut treatment at Micro Inc. SMP builds density over three sessions, each lasting a few hours with minimal downtime. Ideal for those with male pattern baldness, this technique creates a natural, shaved head look. We work closely with you to design your desired hairline through detailed consultations, ensuring the best outcome. For optimal results, avoid washing the treated area for 4 days post-session."}
                    text3={"Explore SMP for Men"}
                    image1={SMP_FOR_MEN_CARD1_IMAGE_1}
                    image2={SMP_FOR_MEN_CARD1_IMAGE_2}
                    onClickAction={() => {
                    }}
                ></TreatmentCard>
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={"Density enhancement"}
                    text2={"Scalp micropigmentation (SMP) effectively reduces the visibility of hair loss by creating the illusion of thicker hair. Suitable for both men and women, SMP delivers immediate results, blending seamlessly with your natural hairline. It can be combined with other hair loss treatments and doesn't hinder future hair transplants. Sessions, scheduled 7 to 10 days apart, typically last a few hours with minimal downtime."}
                    text3={"Discover SMP for Women"}
                    image1={SMP_FOR_MEN_CARD2_IMAGE_1}
                    image2={SMP_FOR_MEN_CARD2_IMAGE_2}
                    onClickAction={() => {
                    }}
                ></TreatmentCard>
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={"Combine Hair Transplant with Scalp Micropigmentation (SMP)"}
                    text2={"Combining hair transplant and scalp micropigmentation is increasingly popular. Some opt for SMP six months post-transplant to enhance density, while others choose SMP before a transplant for a gradual transformation or to reduce grafts needed. This combination effectively achieves a fuller head of hair, and SMP is a viable alternative for those unsatisfied with previous transplants."}
                    text3={"Learn about SMP for Alopecia"}
                    image1={SMP_FOR_MEN_CARD3_IMAGE_1}
                    image2={SMP_FOR_MEN_CARD3_IMAGE_2}
                    onClickAction={() => {
                    }}
                ></TreatmentCard>
            </div>
            <button style={{
                              'position': 'absolute',
                              'top': '50%',
                              'transform': 'translateY(-50%)',
                              'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                              'color': 'white',
                              'border': 'none',
                              'padding': '10px 10px 10px 5px',
                              'borderRadius': '50%',
                              'width': '40px',
                              'height': '80px',
                              'borderRadius': '0px 45px 45px 0',
                              'cursor': 'pointer',
                              'zIndex': '1',
                              'display': 'flex',
                              'alignItems': 'center',
                              'justifyContent': 'center',
                              'left': '0px',
                          }} onClick={() => scrollLeft()}>
                          <FaChevronLeft size={25} />
                        </button>
                        <button style={{
                              'position': 'absolute',
                              'top': '50%',
                              'transform': 'translateY(-50%)',
                              'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                              'color': 'white',
                              'border': 'none',
                              'padding': '10px 5px 10px 10px',
                              'borderRadius': '50%',
                              'width': '40px',
                              'height': '80px',
                              'borderRadius': '45px 0 0 45px',
                              'cursor': 'pointer',
                              'zIndex': '1',
                              'display': 'flex',
                              'alignItems': 'center',
                              'justifyContent': 'center',
                              'right': '0px',
                          }} onClick={() => scrollRight()}>
                          <FaChevronRight size={25} />
                        </button>
            </div>
            {/* <div className='treatment-for-men-cards2'>
            </div> */}
        </div>
    );
}

function TreatmentsForMenMainMobile() {
    const navigate = useNavigate();
    const scrollRef = useRef(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            const { scrollLeft } = scrollRef.current;
            if (scrollLeft > 0) {  // Only scroll if not already at the start
                scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
            }
        }
    };
    
    const scrollRight = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            const maxScroll = scrollWidth - clientWidth;
    
            if (scrollLeft < maxScroll) {  // Only scroll if not already at the end
                scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
            }
        }
    };
    return (
        <div className='treatments-for-men-main-mobile'>
            <div className='treatments-for-men-head'>
                <div className='treatments-for-men-head-column'>
                    <div className='treatments-for-men-text'>
                        TREATMENTS
                    </div>
                    <div className='treatments-for-men-text2'>
                        Overcoming hair loss is finally easy!
                    </div>
                </div>
            </div>
            <div style={{ 
                width: "90%",
                position: "relative", 
                display: "flex",
            }}>
            <div ref={scrollRef} className='treatment-for-men-cards'>
                <TreatmentCard
                    text1={"Crew cut"}
                    text2={"Our crew cut treatment at Micro Inc. SMP builds density over three sessions, each lasting a few hours with minimal downtime. Ideal for those with male pattern baldness, this technique creates a natural, shaved head look. We work closely with you to design your desired hairline through detailed consultations, ensuring the best outcome. For optimal results, avoid washing the treated area for 4 days post-session."}
                    text3={"Explore SMP for Men"}
                    image1={SMP_FOR_MEN_CARD1_IMAGE_1}
                    image2={SMP_FOR_MEN_CARD1_IMAGE_2}
                    onClickAction={() => {
                    }}
                ></TreatmentCard>
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={"Density enhancement"}
                    text2={"Scalp micropigmentation (SMP) effectively reduces the visibility of hair loss by creating the illusion of thicker hair. Suitable for both men and women, SMP delivers immediate results, blending seamlessly with your natural hairline. It can be combined with other hair loss treatments and doesn't hinder future hair transplants. Sessions, scheduled 7 to 10 days apart, typically last a few hours with minimal downtime."}
                    text3={"Discover SMP for Women"}
                    image1={SMP_FOR_MEN_CARD2_IMAGE_1}
                    image2={SMP_FOR_MEN_CARD2_IMAGE_2}
                    onClickAction={() => {
                    }}
                ></TreatmentCard>
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={"Combine Hair Transplant with Scalp Micropigmentation (SMP)"}
                    text2={"Combining hair transplant and scalp micropigmentation is increasingly popular. Some opt for SMP six months post-transplant to enhance density, while others choose SMP before a transplant for a gradual transformation or to reduce grafts needed. This combination effectively achieves a fuller head of hair, and SMP is a viable alternative for those unsatisfied with previous transplants."}
                    text3={"Learn about SMP for Alopecia"}
                    image1={SMP_FOR_MEN_CARD3_IMAGE_1}
                    image2={SMP_FOR_MEN_CARD3_IMAGE_2}
                    onClickAction={() => {
                    }}
                ></TreatmentCard>
                {/* <TreatmentCard
                    text1={"SMP for scars"}
                    text2={"Conceal scars and imperfections with our innovative SMP techniques, seamlessly blending them with your natural hairline. Restore both appearance and confidence with our personalized solutions."}
                    text3={"Explore SMP for Scars"}
                    onClickAction={() => {
                    }}
                ></TreatmentCard> */}
            </div>
                        <button style={{
                              'position': 'absolute',
                              'top': '50%',
                              'transform': 'translateY(-50%)',
                              'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                              'color': 'white',
                              'border': 'none',
                              'padding': '10px 10px 10px 5px',
                              'borderRadius': '50%',
                              'width': '40px',
                              'height': '80px',
                              'borderRadius': '0px 45px 45px 0',
                              'cursor': 'pointer',
                              'zIndex': '1',
                              'display': 'flex',
                              'alignItems': 'center',
                              'justifyContent': 'center',
                              'left': '0px',
                          }} onClick={() => scrollLeft()}>
                          <FaChevronLeft size={25} />
                        </button>
                        <button style={{
                              'position': 'absolute',
                              'top': '50%',
                              'transform': 'translateY(-50%)',
                              'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                              'color': 'white',
                              'border': 'none',
                              'padding': '10px 5px 10px 10px',
                              'borderRadius': '50%',
                              'width': '40px',
                              'height': '80px',
                              'borderRadius': '45px 0 0 45px',
                              'cursor': 'pointer',
                              'zIndex': '1',
                              'display': 'flex',
                              'alignItems': 'center',
                              'justifyContent': 'center',
                              'right': '0px',
                          }} onClick={() => scrollRight()}>
                          <FaChevronRight size={25} />
                        </button>
            </div>
        </div>
    );
}

function TreatmentCard({ text1, text2, text3, image1, image2, onClickAction }) {
    return (
        <div className='treatment-for-men-card-component-container' onClick={onClickAction}>
            <div className='treatment-for-men-card-component-in'>
                <div className='treatment-for-men-card-component-images'>
                    <div className='treatment-for-men-card-component-image'>
                        <img src={image1} height={"100%"} style={{ borderTopLeftRadius: "10px" }}></img>
                    </div>
                    <div className='treatment-for-men-card-component-image'>
                        <img src={image2} height={"100%"} style={{ borderTopRightRadius: "10px" }}></img>
                    </div>
                </div>
                <div className='treatment-for-men-card-component-content-area'>
                    <div className='treatment-for-men-card-component-text0'>
                        {/* SMP for men */}
                        {text1}
                    </div>
                    <div className='treatment-for-men-card-component-text1'>
                        {/* "I don't think about my hair anymore. I get dressed, get out of bed, and go to work, that's it. It looks so natural and so real! I just wish I had gotten it done sooner." */}
                        {text2}
                    </div>
                    {/* <div className='treatment-for-men-card-component-text2'>
                        {text3}
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default TreatmentsForMen;
