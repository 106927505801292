import React, { useState, useRef } from 'react';
import '../styles/HomePage/HomePage.css';
import WhyNumber1 from '../components/HomePage/WhyNumber1';
import ConsultationForm from '../components/HomePage/ConsultationForm';
import Faq from '../components/HomePage/Faq';
import Footer from '../components/HomePage/Footer';
import About from '../components/HomePage/About';
import Navbar from '../components/HomePage/Navbar';
import Home from '../components/HomePage/Home';
import Treatments from '../components/HomePage/Treatments';
import CompareChart from '../components/HomePage/CompareChart';
import { useSelector, useDispatch } from 'react-redux';
import { selectShowDialog, setShowDialog } from '../state/slices'
import { selectDialogTitle, setDialogTitle } from '../state/slices'
import { selectDialogDesc, setDialogDesc } from '../state/slices'
import { selectShowLoading } from '../state/slices'
import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton';
import TestimonialsHome from '../components/TestimonialsHome';
import Highlights from '../components/Highlights';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { AiOutlineClose } from 'react-icons/ai';

function HighlightsOverlay({hideTheOverlay, videoId}) {
    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }

    return (
        <div style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            zIndex: 999,
            pointerEvents: "auto", // Ensures overlay captures clicks
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }} onClick={hideTheOverlay}>
                    {/* Close button */}
            <button 
                onClick={(e) => { 
                    e.stopPropagation(); // Prevents overlay click from closing
                    // toggleOverlay();
                    hideTheOverlay();
                }}
                style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    background: "transparent",
                    border: "none",
                    fontSize: "24px",
                    color: "black",
                    cursor: "pointer",
                    zIndex: 1000, // Makes sure the close button stays above everything
                }}
            >
                <AiOutlineClose size={30} /> {/* React Icon for close */}
            </button>
            <div style={reValue({
                desktop: { position: "relative", width: "300px", height: "535px", background: "#fff", borderRadius: "10px", overflow: "hidden" },
                tablet: { position: "relative", width: "40%", height: "80%", background: "#fff", borderRadius: "10px", overflow: "hidden" },
                mobile: { position: "relative", width: "68%", height: "80%", background: "#fff", borderRadius: "10px", overflow: "hidden" }
            })} onClick={(e) => e.stopPropagation()}>
                    <iframe
                        width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=0&controls=0&rel=0`}
                        title="YouTube Short"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
    );
}

export default HighlightsOverlay;