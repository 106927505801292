import React, { useRef } from 'react';
import '../styles/Testimonials.css';
import { BiChevronLeftCircle, BiChevronRightCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { SMP_FOR_ALOPECIA_URL_1, SMP_FOR_ALOPECIA_URL_2, SMP_FOR_SCARS_URL_1, SMP_FOR_SCARS_URL_2, TREATMENTS_HEADING_1, TREATMENTS_HEADING_2, SMP_FOR_MEN_CARD_TITLE, SMP_FOR_MEN_CARD_DESCRIPTION, SMP_FOR_MEN_CARD_FOOTER, SMP_FOR_WOMEN_CARD_TITLE, SMP_FOR_WOMEN_CARD_DESCRIPTION, SMP_FOR_WOMEN_CARD_FOOTER, SMP_FOR_ALOPECIA_CARD_TITLE, SMP_FOR_ALOPECIA_CARD_DESCRIPTION, SMP_FOR_ALOPECIA_CARD_FOOTER, SMP_FOR_SCARS_CARD_TITLE, SMP_FOR_SCARS_CARD_DESCRIPTION, SMP_FOR_SCARS_CARD_FOOTER } from '../constants';
import { MdOutlineStar } from "react-icons/md";

function Testimonials() {
    return (
        <div>
            <TestimonialsMainDesktop></TestimonialsMainDesktop>
            <TestimonialsMainTablet></TestimonialsMainTablet>
            <TestimonialsMainMobile></TestimonialsMainMobile>
        </div>
    );
};

function TestimonialsMainDesktop() {
    const navigate = useNavigate();
    const styles = {
        buttonContainer: {
            // backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItms: 'center',
            fontSize: '30px',
            margin: '0px 5px 0px 0px'
        },
        icon: {
            cursor: 'pointer',
            color: '#c5c5c5'
        },
    };

    const scrollRef = useRef(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            const { scrollLeft } = scrollRef.current;
            if (scrollLeft > 0) {  // Only scroll if not already at the start
                scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
            }
        }
    };
    
    const scrollRight = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            const maxScroll = scrollWidth - clientWidth;
    
            if (scrollLeft < maxScroll) {  // Only scroll if not already at the end
                scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
            }
        }
    };

    return (
        <div className='testimonials-main-desktop'>
            <div className='testimonials-head'>
                <div className='testimonials-head-column'>
                    {/* <div className='testimonials-text'>
                        TESTIMONIALS
                    </div> */}
                    <div className='testimonials-text2'>
                        Reviews
                    </div>
                </div>
                <div style={styles.buttonContainer}>
                    <BiChevronLeftCircle onClick={scrollLeft} style={styles.icon} />
                    <div style={{ width: '15px' }}></div>
                    <BiChevronRightCircle onClick={scrollRight} style={styles.icon} />
                </div>
            </div>
            <div ref={scrollRef} className='testimonial-cards'>
                <TestimonialCard
                    text1="Geroge Fernandes"
                    text2="I recently had an exceptional experience at Micro Inc. SMP! The expertise at Micro Inc. SMP shone through as they patiently addressed all my queries, instilling in me a sense of confidence about the procedure. Under the skilled hands of Chetan Mahajan, I achieved natural-looking results that have significantly bolstered my self-assurance. Moreover, the minimal post-procedure care required is a definite advantage. I wholeheartedly recommend Micro Inc. for those seeking a non-surgical hair loss solution - it truly transformed my life!"
                    text3={SMP_FOR_MEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpformen/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Clara Thomas"
                    text2="I dealt with hair loss as a woman for the past 3 years. I wasn't confident about how I looked. I tried almost every possible treatment to improve the condition but I wasn't happy. I was nervous at the start of the process but Chetan walked me through, explaining what he will be doing and made me comfortable. It was a painless process. Now my confidence is back and I can style and wear my hair in any way I want too. I can't thank Micro Inc. SMP enough for helping me bring this change in my life."
                    text3={SMP_FOR_WOMEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforwomen/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Ronak Motta"
                    text2="Life Changing SMP Experience. I recently underwent the scalp micropigmentation procedure, and I couldn't be more thrilled with the results. As someone who has struggled with hair loss for years and has had a bald scalp, SMP has been a game-changer for me. From the initial consultation to the final session, the entire experience at Micro Inc. SMP was exceptional."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
            </div>
        </div>
    );
}

function TestimonialsMainTablet() {
    const navigate = useNavigate();
    return (
        <div className='testimonials-main-tablet'>
            <div className='testimonials-head'>
                <div className='testimonials-head-column'>
                    {/* <div className='testimonials-text'>
                        TESTIMONIALS
                    </div> */}
                    <div className='testimonials-text2'>
                        Reviews
                    </div>
                </div>
            </div>
            <div className='testimonial-cards1'>
                <TestimonialCard
                    text1="Geroge Fernandes"
                    text2="I recently had an exceptional experience at Micro Inc. SMP! The expertise at Micro Inc. SMP shone through as they patiently addressed all my queries, instilling in me a sense of confidence about the procedure. Under the skilled hands of Chetan Mahajan, I achieved natural-looking results that have significantly bolstered my self-assurance. Moreover, the minimal post-procedure care required is a definite advantage. I wholeheartedly recommend Micro Inc. for those seeking a non-surgical hair loss solution - it truly transformed my life!"
                    text3={SMP_FOR_MEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpformen/");
                    }}
                ></TestimonialCard>
                <TestimonialCard
                    text1="Clara Thomas"
                    text2="I dealt with hair loss as a woman for the past 3 years. I wasn't confident about how I looked. I tried almost every possible treatment to improve the condition but I wasn't happy. I was nervous at the start of the process but Chetan walked me through, explaining what he will be doing and made me comfortable. It was a painless process. Now my confidence is back and I can style and wear my hair in any way I want too. I can't thank Micro Inc. SMP enough for helping me bring this change in my life."
                    text3={SMP_FOR_WOMEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforwomen/");
                    }}
                ></TestimonialCard>
            </div>
            <div className='testimonial-cards2'>
                <TestimonialCard
                    text1="Ronak Motta"
                    text2="Life Changing SMP Experience. I recently underwent the scalp micropigmentation procedure, and I couldn't be more thrilled with the results. As someone who has struggled with hair loss for years and has had a bald scalp, SMP has been a game-changer for me. From the initial consultation to the final session, the entire experience at Micro Inc. SMP was exceptional."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
            </div>
        </div>
    );
}

function TestimonialsMainMobile() {
    const navigate = useNavigate();
    return (
        <div className='testimonials-main-mobile'>
            <div className='testimonials-head'>
                <div className='testimonials-head-column'>
                    {/* <div className='testimonials-text'>
                        TESTIMONIALS
                    </div> */}
                    <div className='testimonials-text2'>
                        Reviews
                    </div>
                </div>
            </div>
            <div className='testimonial-cards'>
                <TestimonialCard
                    text1="George Fernandes"
                    text2="I recently had an exceptional experience at Micro Inc. SMP! The expertise at Micro Inc. SMP shone through as they patiently addressed all my queries, instilling in me a sense of confidence about the procedure. Under the skilled hands of Chetan Mahajan, I achieved natural-looking results that have significantly bolstered my self-assurance. Moreover, the minimal post-procedure care required is a definite advantage. I wholeheartedly recommend Micro Inc. for those seeking a non-surgical hair loss solution - it truly transformed my life!"
                    text3={SMP_FOR_MEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpformen/");
                    }}
                ></TestimonialCard>
                <TestimonialCard
                    text1="Clara Thomas"
                    text2="I dealt with hair loss as a woman for the past 3 years. I wasn't confident about how I looked. I tried almost every possible treatment to improve the condition but I wasn't happy. I was nervous at the start of the process but Chetan walked me through, explaining what he will be doing and made me comfortable. It was a painless process. Now my confidence is back and I can style and wear my hair in any way I want too. I can't thank Micro Inc. SMP enough for helping me bring this change in my life."
                    text3={SMP_FOR_WOMEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforwomen/");
                    }}
                ></TestimonialCard>
                <TestimonialCard
                    text1="Ronak Motta"
                    text2="Life Changing SMP Experience. I recently underwent the scalp micropigmentation procedure, and I couldn't be more thrilled with the results. As someone who has struggled with hair loss for years and has had a bald scalp, SMP has been a game-changer for me. From the initial consultation to the final session, the entire experience at Micro Inc. SMP was exceptional."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
            </div>
        </div>
    );
}

function TestimonialCard({ text1, text2, text3, image1, image2, onClickAction }) {
    return (
        <div className='testimonial-card-component-container' >
            <div className='testimonial-card-component-in'>
                <div className='testimonial-card-component-content-area'>
                    <div className='testimonial-card-component-text0'>
                        {text1}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <MdOutlineStar style={{ color: '#e9be47' }} />
                            <MdOutlineStar style={{ color: '#e9be47' }} />
                            <MdOutlineStar style={{ color: '#e9be47' }} />
                            <MdOutlineStar style={{ color: '#e9be47' }} />
                            <MdOutlineStar style={{ color: '#e9be47' }} />
                        </div>
                    </div>
                    <div className='testimonial-card-component-text1'>
                        "{text2}"
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Testimonials;