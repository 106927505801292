import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import TestPage from './pages/TestPage';
import HomePage from './pages/HomePage';
import reportWebVitals from './reportWebVitals';
import store from './state/store'
import { Provider } from 'react-redux'
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import FaqPage from './pages/FaqPage';
import SmpForMenPage from './pages/SmpForMenPage';
import SmpForWomenPage from './pages/SmpForWomenPage';
import SmpForAlopeciaPage from './pages/SmpForAlopeciaPage';
import SmpForScarsPage from './pages/SmpForScarsPage';
import BlogPage from './pages/BlogPage';
import BlogDetailsPage from './pages/BlogDetailsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsAndConditions from './pages/TermsAndConditions';
import ThankyouPage from './pages/ThankyouPage';
import App from './App';
import HighlightsPage from './pages/HighlightsPage';

const handleResize = () => {
  console.log("Current width:", window.innerWidth);
};

// Add event listener to handle window resize
window.addEventListener('resize', handleResize);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {/* Route (1) ... ... ... */}
      <Route path="/" element={<HomePage />} />
      {/* Route (2) ... ... ... */}
      <Route path="faq/" element={<FaqPage />} />
      {/* Route (3) ... ... ... */}
      <Route path="smpformen/" element={<SmpForMenPage />} />
      {/* Route (4) ... ... ... */}
      <Route path="smpforwomen/" element={<SmpForWomenPage />} />
      {/* Route (5) ... ... ... */}
      <Route path="smpforalopecia/" element={<SmpForAlopeciaPage />} />
      {/* Route (6) ... ... ... */}
      <Route path="smpforscars/" element={<SmpForScarsPage />} />
      {/* Route (7) ... ... ... */}
      <Route path="blog/" element={<BlogPage />} />
      {/* Route (8) ... ... ... */}
      <Route path="blog/:slug" element={<BlogDetailsPage />} />
      {/* Route (9) ... ... ... */}
      <Route path="privacypolicy/" element={<PrivacyPolicyPage />} />
      {/* Route (10) ... ... ... */}
      <Route path="termsandconditions/" element={<TermsAndConditions />} />
      {/* Route (11) ... ... ... */}
      <Route path="thankyoupage/" element={<ThankyouPage />} />
      {/* Route (12) ... ... ... */}
      <Route path="highlights/" element={<HighlightsPage />} />
      {/* Route (13) ... ... ... */}
      {/* <Route path="test/" element={<TestPage />} /> */}
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
