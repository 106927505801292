import React, { useState, useRef } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { BiChevronLeftCircle, BiChevronRightCircle } from "react-icons/bi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function Highlights({showTheOverlay}) {
    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;
  
    const styles = {
      buttonContainer: {
          // backgroundColor: 'red',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItms: 'center',
          fontSize: '30px',
          margin: '0px 5px 0px 0px'
      },
      icon: {
          cursor: 'pointer',
          color: '#c5c5c5'
      },
    };
  
    const scrollRef = useRef(null);
    
    const scrollLeft = () => {
        if (scrollRef.current) {
            const { scrollLeft } = scrollRef.current;
            if (scrollLeft > 0) {  // Only scroll if not already at the start
                scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
            }
        }
    };
    
    const scrollRight = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            const maxScroll = scrollWidth - clientWidth;
    
            if (scrollLeft < maxScroll) {  // Only scroll if not already at the end
                scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
            }
        }
    };
  
    const cards = [
        { id: 2, title: 'Short 2', description: 'Description for Short 2', videoId: 'NxXENH1r1Xc', videoUrl: 'https://www.youtube.com/shorts/NxXENH1r1Xc' },
        { id: 4, title: 'Short 4', description: 'Description for Short 4', videoId: '5RzEcanl7CY', videoUrl: 'https://www.youtube.com/shorts/5RzEcanl7CY' },
        { id: 10, title: 'Short 10', description: 'Description for Short 10', videoId: 'J7JEw9q0ZM8', videoUrl: 'https://www.youtube.com/shorts/J7JEw9q0ZM8' },
        { id: 11, title: 'Short 11', description: 'Description for Short 11', videoId: 'eDAP3VPnCTY', videoUrl: 'https://www.youtube.com/shorts/eDAP3VPnCTY' },
        { id: 13, title: 'Short 13', description: 'Description for Short 13', videoId: 'yBovl5XcxVg', videoUrl: 'https://www.youtube.com/shorts/yBovl5XcxVg' },
        { id: 14, title: 'Short 14', description: 'Description for Short 14', videoId: 'C_8AXRz8nJE', videoUrl: 'https://www.youtube.com/shorts/C_8AXRz8nJE' },
    ];
  
    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }
  
    // State to handle hover effect
    const [hoveredCard, setHoveredCard] = useState(null);
  
    return (
      <div style={reValue({
        desktop: { display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center', width: '100vw', height: 'fitContent', margin: '0px 0px 0px 0px'},
        tablet: { display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", width: "100vw", height: "fit-content", margin: "0px 0px 0px 0px" },
        mobile: { display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", width: "100vw", height: "fit-content", margin: "0px 0px 0px 0px" }
      })}>
        <div style={reValue({
          desktop: { display: "flex", flexDirection: "row", width: "90%", justifyContent: "space-between", alignItems: "start", height: "fit-content" },
          tablet: { display: "flex", flexDirection: "row", width: "80%", justifyContent: "space-between", alignItems: "start", height: "fit-content" },
          mobile: { display: "flex", flexDirection: "row", width: "90%", justifyContent: "space-between", height: "fit-content" },
        })}>
            <div style={reValue({
              desktop: { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", height: "fit-content", width: "fit-content" },
              tablet: { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", height: "fit-content", width: "fit-content" },
              mobile: { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", height: "fit-content", width: "fit-content" },
            })}>
                <div style={reValue({
                  desktop: { fontWeight: "bold", fontSize: "28px", margin: "0px 0px 40px 0px" },
                  tablet: { fontWeight: "bold", fontSize: "28px", margin: "0px 0px 40px 0px" },
                  mobile: { fontWeight: "bold", fontSize: "28px", margin: "0px 0px 40px 0px" },
                })}>
                    SMP Stories
                </div>
            </div>
            <div style={reValue({
              desktop: { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItms: 'center', fontSize: '30px', margin: '0px 5px 0px 0px' },
              tablet: { display: 'none', flexDirection: 'row', justifyContent: 'center', alignItms: 'center', fontSize: '30px', margin: '0px 5px 0px 0px' },
              mobile: { display: 'none', flexDirection: 'row', justifyContent: 'center', alignItms: 'center', fontSize: '30px', margin: '0px 5px 0px 0px' },
            })}>
                <BiChevronLeftCircle onClick={scrollLeft} style={styles.icon} />
                <div style={{ width: '15px' }}></div>
                <BiChevronRightCircle onClick={scrollRight} style={styles.icon} />
            </div>
        </div>
        <div style={reValue({
            desktop: { 
                width: "90%",
                position: "relative", 
                display: "flex",
            },
            tablet: { 
                width: "80%",
                position: "relative", 
                display: "flex",
            },
            mobile: { 
                width: "90%",
                position: "relative", 
                display: "flex",
            },
        })}>
        <div ref={scrollRef} style={reValue({
            desktop: { 
                width: '100%', 
                overflowX: 'auto', 
                whiteSpace: 'nowrap', 
                scrollbarWidth: 'none' 
            },
            tablet: { 
                width: '100%', 
                overflowX: 'auto', 
                whiteSpace: 'nowrap', 
                scrollbarWidth: 'none' 
            },
            mobile: { 
                width: '100%', 
                overflowX: 'auto', 
                whiteSpace: 'nowrap', 
                scrollbarWidth: 'none' 
            },
        })}>
            {cards.map((card) => (
                <div 
                    key={card.id} 
                    style={reValue({
                        desktop: {
                          backgroundColor: '#fff',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            display: 'inline-block',
                            flexDirection: 'column',
                            height: '540px',
                            width: 'auto',
                            justifyContent: 'flex-start',
                            maxWidth: '300px',
                            margin: '0px 38px 0px 0px',
                            position: 'relative',
                            overflow: 'hidden',
                            cursor: 'pointer'
                        },
                        tablet: {
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            display: 'inline-block',
                            flexDirection: 'column',
                            height: '540px',
                            width: 'auto',
                            justifyContent: 'flex-start',
                            maxWidth: '300px',
                            margin: '0px 28px 0px 0px',
                            position: 'relative',
                            overflow: 'hidden',
                            cursor: 'pointer'
                        },
                        mobile: {
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            display: 'inline-block',
                            flexDirection: 'column',
                            height: '80vw',
                            width: 'auto',
                            justifyContent: 'flex-start',
                            maxWidth: '48.5%',
                            margin: '0px 10px 0px 0px',
                            position: 'relative',
                            overflow: 'hidden',
                            cursor: 'pointer'
                        }
                    })}
                    // onMouseEnter={() => setHoveredCard(card.id)}
                    // onMouseLeave={() => setHoveredCard(null)}
                    onClick={ () => showTheOverlay(card.videoId)}
                >
                    <img 
                        width="100%" 
                        height="100%"
                        src={`https://img.youtube.com/vi/${card.videoId}/0.jpg`}
                        alt={`Thumbnail for ${card.title}`}
                        style={{
                            objectFit: 'cover',
                            padding: '0',
                            margin: '0',
                            borderRadius: '8px',
                        }}
                    />
                    <div 
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: hoveredCard === card.id ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0)',
                            transition: 'background-color 0.3s ease-in-out',
                            borderRadius: '8px',
                        }}
                    />
                </div>
            ))}
        </div>

            {
                reValue({
                    desktop: <button style={{
                        'position': 'absolute',
                        'top': '50%',
                        'transform': 'translateY(-50%)',
                        'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                        'color': 'white',
                        'border': 'none',
                        'padding': '10px 10px 10px 5px',
                        'borderRadius': '50%',
                        'width': '40px',
                        'height': '80px',
                        'borderRadius': '0px 45px 45px 0',
                        'cursor': 'pointer',
                        'zIndex': '1',
                        'display': 'none',
                        'alignItems': 'center',
                        'justifyContent': 'center',
                        'left': '0px',
                    }} onClick={() => scrollLeft()}> <FaChevronLeft size={25} />
                    </button>,
                    tablet: <button style={{
                        'position': 'absolute',
                        'top': '50%',
                        'transform': 'translateY(-50%)',
                        'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                        'color': 'white',
                        'border': 'none',
                        'padding': '10px 10px 10px 5px',
                        'borderRadius': '50%',
                        'width': '40px',
                        'height': '80px',
                        'borderRadius': '0px 45px 45px 0',
                        'cursor': 'pointer',
                        'zIndex': '1',
                        'display': 'flex',
                        'alignItems': 'center',
                        'justifyContent': 'center',
                        'left': '0px',
                    }} onClick={() => scrollLeft()}> <FaChevronLeft size={25} />
                    </button>,
                    mobile: <button style={{
                        'position': 'absolute',
                        'top': '50%',
                        'transform': 'translateY(-50%)',
                        'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                        'color': 'white',
                        'border': 'none',
                        'padding': '10px 10px 10px 5px',
                        'borderRadius': '50%',
                        'width': '40px',
                        'height': '80px',
                        'borderRadius': '0px 45px 45px 0',
                        'cursor': 'pointer',
                        'zIndex': '1',
                        'display': 'flex',
                        'alignItems': 'center',
                        'justifyContent': 'center',
                        'left': '0px',
                    }} onClick={() => scrollLeft()}> <FaChevronLeft size={25} />
                    </button>
                })
            }

            {
                reValue({
                    desktop: <button style={{
                        'position': 'absolute',
                        'top': '50%',
                        'transform': 'translateY(-50%)',
                        'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                        'color': 'white',
                        'border': 'none',
                        'padding': '10px 5px 10px 10px',
                        'borderRadius': '50%',
                        'width': '40px',
                        'height': '80px',
                        'borderRadius': '45px 0 0 45px',
                        'cursor': 'pointer',
                        'zIndex': '1',
                        'display': 'none',
                        'alignItems': 'center',
                        'justifyContent': 'center',
                        'right': '0px',
                    }} onClick={() => scrollRight()}>
                    <FaChevronRight size={25} />
                    </button>,
                    tablet: <button style={{
                        'position': 'absolute',
                        'top': '50%',
                        'transform': 'translateY(-50%)',
                        'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                        'color': 'white',
                        'border': 'none',
                        'padding': '10px 5px 10px 10px',
                        'borderRadius': '50%',
                        'width': '40px',
                        'height': '80px',
                        'borderRadius': '45px 0 0 45px',
                        'cursor': 'pointer',
                        'zIndex': '1',
                        'display': 'flex',
                        'alignItems': 'center',
                        'justifyContent': 'center',
                        'right': '0px',
                    }} onClick={() => scrollRight()}>
                    <FaChevronRight size={25} />
                    </button>,
                    mobile: <button style={{
                        'position': 'absolute',
                        'top': '50%',
                        'transform': 'translateY(-50%)',
                        'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                        'color': 'white',
                        'border': 'none',
                        'padding': '10px 5px 10px 10px',
                        'borderRadius': '50%',
                        'width': '40px',
                        'height': '80px',
                        'borderRadius': '45px 0 0 45px',
                        'cursor': 'pointer',
                        'zIndex': '1',
                        'display': 'flex',
                        'alignItems': 'center',
                        'justifyContent': 'center',
                        'right': '0px',
                    }} onClick={() => scrollRight()}>
                    <FaChevronRight size={25} />
                    </button>
                })
            }

        </div>
      </div>
    );
  }

export default Highlights;
