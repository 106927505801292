import React, { useRef } from 'react';
import '../../styles/HomePage/Treatments.css';
import { BiChevronLeftCircle, BiChevronRightCircle } from "react-icons/bi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { SMP_FOR_MEN_URL_1, SMP_FOR_MEN_URL_2, SMP_FOR_WOMEN_URL_1, SMP_FOR_WOMEN_URL_2, SMP_FOR_ALOPECIA_URL_1, SMP_FOR_ALOPECIA_URL_2, SMP_FOR_SCARS_URL_1, SMP_FOR_SCARS_URL_2, TREATMENTS_HEADING_1, TREATMENTS_HEADING_2, SMP_FOR_MEN_CARD_TITLE, SMP_FOR_MEN_CARD_DESCRIPTION, SMP_FOR_MEN_CARD_FOOTER, SMP_FOR_WOMEN_CARD_TITLE, SMP_FOR_WOMEN_CARD_DESCRIPTION, SMP_FOR_WOMEN_CARD_FOOTER, SMP_FOR_ALOPECIA_CARD_TITLE, SMP_FOR_ALOPECIA_CARD_DESCRIPTION, SMP_FOR_ALOPECIA_CARD_FOOTER, SMP_FOR_SCARS_CARD_TITLE, SMP_FOR_SCARS_CARD_DESCRIPTION, SMP_FOR_SCARS_CARD_FOOTER } from '../../constants';

function Treatments() {
    return (
        <div>
            <TreatmentsMainDesktop></TreatmentsMainDesktop>
            <TreatmentsMainTablet></TreatmentsMainTablet>
            <TreatmentsMainMobile></TreatmentsMainMobile>
        </div>
    );
};

function TreatmentsMainDesktop() {
    const navigate = useNavigate();
    const styles = {
        buttonContainer: {
            // backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItms: 'center',
            fontSize: '30px',
            margin: '0px 5px 0px 0px'
        },
        icon: {
            cursor: 'pointer',
            color: 'rgb(197, 197, 197)'
        },
    };

    const scrollRef = useRef(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            const { scrollLeft } = scrollRef.current;
            if (scrollLeft > 0) {  // Only scroll if not already at the start
                scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
            }
        }
    };
    
    const scrollRight = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            const maxScroll = scrollWidth - clientWidth;
    
            if (scrollLeft < maxScroll) {  // Only scroll if not already at the end
                scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
            }
        }
    };
    return (
        <div className='treatments-main-desktop'>
            <div className='treatments-head'>
                <div className='treatments-head-column'>
                    <div className='treatments-text'>
                        {TREATMENTS_HEADING_1}
                    </div>
                    <div className='treatments-text2'>
                        {TREATMENTS_HEADING_2}
                    </div>
                </div>
                <div style={styles.buttonContainer}>
                    <BiChevronLeftCircle onClick={scrollLeft} style={styles.icon} />
                    <div style={{ width: '15px' }}></div>
                    <BiChevronRightCircle onClick={scrollRight} style={styles.icon} />
                </div>
            </div>
            <div ref={scrollRef} className='treatment-cards'>
                <TreatmentCard
                    text1={SMP_FOR_MEN_CARD_TITLE}
                    text2={SMP_FOR_MEN_CARD_DESCRIPTION}
                    text3={SMP_FOR_MEN_CARD_FOOTER}
                    image1={SMP_FOR_MEN_URL_1}
                    image2={SMP_FOR_MEN_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpformen/");
                    }}
                ></TreatmentCard>
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={SMP_FOR_WOMEN_CARD_TITLE}
                    text2={SMP_FOR_WOMEN_CARD_DESCRIPTION}
                    text3={SMP_FOR_WOMEN_CARD_FOOTER}
                    image1={SMP_FOR_WOMEN_URL_1}
                    image2={SMP_FOR_WOMEN_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforwomen/");
                    }}
                ></TreatmentCard>
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={SMP_FOR_ALOPECIA_CARD_TITLE}
                    text2={SMP_FOR_ALOPECIA_CARD_DESCRIPTION}
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TreatmentCard>
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={SMP_FOR_SCARS_CARD_TITLE}
                    text2={SMP_FOR_SCARS_CARD_DESCRIPTION}
                    text3={SMP_FOR_SCARS_CARD_FOOTER}
                    image1={SMP_FOR_SCARS_URL_1}
                    image2={SMP_FOR_SCARS_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforscars/");
                    }}
                ></TreatmentCard>
            </div>
        </div>
    );
}

function TreatmentsMainTablet() {
    const navigate = useNavigate();
    const scrollRef = useRef(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            const { scrollLeft } = scrollRef.current;
            if (scrollLeft > 0) {  // Only scroll if not already at the start
                scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
            }
        }
    };
    
    const scrollRight = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            const maxScroll = scrollWidth - clientWidth;
    
            if (scrollLeft < maxScroll) {  // Only scroll if not already at the end
                scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
            }
        }
    };
    
    return (
        <div className='treatments-main-tablet'>
            <div className='treatments-head'>
                <div className='treatments-head-column'>
                    <div className='treatments-text'>
                        {TREATMENTS_HEADING_1}
                    </div>
                    <div className='treatments-text2'>
                        {TREATMENTS_HEADING_2}
                    </div>
                </div>
            </div>
            <div style={{ 
                width: "80%",
                position: "relative", 
                display: "flex",
            }}>
            <div ref={scrollRef} className='treatment-cards1'>
                <TreatmentCard
                    text1={SMP_FOR_MEN_CARD_TITLE}
                    text2={SMP_FOR_MEN_CARD_DESCRIPTION}
                    text3={SMP_FOR_MEN_CARD_FOOTER}
                    image1={SMP_FOR_MEN_URL_1}
                    image2={SMP_FOR_MEN_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpformen/");
                    }}
                ></TreatmentCard>
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={SMP_FOR_WOMEN_CARD_TITLE}
                    text2={SMP_FOR_WOMEN_CARD_DESCRIPTION}
                    text3={SMP_FOR_WOMEN_CARD_FOOTER}
                    image1={SMP_FOR_WOMEN_URL_1}
                    image2={SMP_FOR_WOMEN_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforwomen/");
                    }}
                ></TreatmentCard>
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={SMP_FOR_ALOPECIA_CARD_TITLE}
                    text2={SMP_FOR_ALOPECIA_CARD_DESCRIPTION}
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TreatmentCard>
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={SMP_FOR_SCARS_CARD_TITLE}
                    text2={SMP_FOR_SCARS_CARD_DESCRIPTION}
                    text3={SMP_FOR_SCARS_CARD_FOOTER}
                    image1={SMP_FOR_SCARS_URL_1}
                    image2={SMP_FOR_SCARS_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforscars/");
                    }}
                ></TreatmentCard>
            </div>
            <button style={{
                  'position': 'absolute',
                  'top': '50%',
                  'transform': 'translateY(-50%)',
                  'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                  'color': 'white',
                  'border': 'none',
                  'padding': '10px 10px 10px 5px',
                  'borderRadius': '50%',
                  'width': '40px',
                  'height': '80px',
                  'borderRadius': '0px 45px 45px 0',
                  'cursor': 'pointer',
                  'zIndex': '1',
                  'display': 'flex',
                  'alignItems': 'center',
                  'justifyContent': 'center',
                  'left': '0px',
              }} onClick={() => scrollLeft()}>
              <FaChevronLeft size={25} />
            </button>
            <button style={{
                  'position': 'absolute',
                  'top': '50%',
                  'transform': 'translateY(-50%)',
                  'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                  'color': 'white',
                  'border': 'none',
                  'padding': '10px 5px 10px 10px',
                  'borderRadius': '50%',
                  'width': '40px',
                  'height': '80px',
                  'borderRadius': '45px 0 0 45px',
                  'cursor': 'pointer',
                  'zIndex': '1',
                  'display': 'flex',
                  'alignItems': 'center',
                  'justifyContent': 'center',
                  'right': '0px',
              }} onClick={() => scrollRight()}>
              <FaChevronRight size={25} />
            </button>
            </div>
            {/* <div className='treatment-cards2'>
                <TreatmentCard
                    text1={SMP_FOR_ALOPECIA_CARD_TITLE}
                    text2={SMP_FOR_ALOPECIA_CARD_DESCRIPTION}
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TreatmentCard>
                <TreatmentCard
                    text1={SMP_FOR_SCARS_CARD_TITLE}
                    text2={SMP_FOR_SCARS_CARD_DESCRIPTION}
                    text3={SMP_FOR_SCARS_CARD_FOOTER}
                    image1={SMP_FOR_SCARS_URL_1}
                    image2={SMP_FOR_SCARS_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforscars/");
                    }}
                ></TreatmentCard>
            </div> */}
        </div>
    );
}

function TreatmentsMainMobile() {
    const navigate = useNavigate();
    const styles = {
        buttonContainer: {
            // backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItms: 'center',
            fontSize: '30px',
            margin: '0px 5px 0px 0px'
        },
        icon: {
            cursor: 'pointer',
            color: 'grey'
        },
    };

    const scrollRef = useRef(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            const { scrollLeft } = scrollRef.current;
            if (scrollLeft > 0) {  // Only scroll if not already at the start
                scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
            }
        }
    };
    
    const scrollRight = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            const maxScroll = scrollWidth - clientWidth;
    
            if (scrollLeft < maxScroll) {  // Only scroll if not already at the end
                scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
            }
        }
    };

    return (
        <div className='treatments-main-mobile'>
            <div className='treatments-head'>
                <div className='treatments-head-column'>
                    <div className='treatments-text'>
                        {TREATMENTS_HEADING_1}
                    </div>
                    <div className='treatments-text2'>
                        {TREATMENTS_HEADING_2}
                    </div>
                </div>
                {/* <div style={styles.buttonContainer}>
                    <BiChevronLeftCircle onClick={scrollLeft} style={styles.icon} />
                    <div style={{ width: '15px' }}></div>
                    <BiChevronRightCircle onClick={scrollRight} style={styles.icon} />
                </div> */}
            </div>
            <div style={{ 
                width: "90%",
                position: "relative", 
                display: "flex",
            }}>
            <div ref={scrollRef} className='treatment-cards'>
                <TreatmentCard
                    text1={SMP_FOR_MEN_CARD_TITLE}
                    text2={SMP_FOR_MEN_CARD_DESCRIPTION}
                    text3={SMP_FOR_MEN_CARD_FOOTER} x
                    image1={SMP_FOR_MEN_URL_1}
                    image2={SMP_FOR_MEN_URL_2}
                    onClickAction={() => {
                        navigate("/smpformen/");
                    }}
                ></TreatmentCard>
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={SMP_FOR_WOMEN_CARD_TITLE}
                    text2={SMP_FOR_WOMEN_CARD_DESCRIPTION}
                    text3={SMP_FOR_WOMEN_CARD_FOOTER}
                    image1={SMP_FOR_WOMEN_URL_1}
                    image2={SMP_FOR_WOMEN_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforwomen/");
                    }}
                ></TreatmentCard>
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={SMP_FOR_ALOPECIA_CARD_TITLE}
                    text2={SMP_FOR_ALOPECIA_CARD_DESCRIPTION}
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TreatmentCard>
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={SMP_FOR_SCARS_CARD_TITLE}
                    text2={SMP_FOR_SCARS_CARD_DESCRIPTION}
                    text3={SMP_FOR_SCARS_CARD_FOOTER}
                    image1={SMP_FOR_SCARS_URL_1}
                    image2={SMP_FOR_SCARS_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforscars/");
                    }}
                ></TreatmentCard>
            </div>
            <button style={{
                  'position': 'absolute',
                  'top': '50%',
                  'transform': 'translateY(-50%)',
                  'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                  'color': 'white',
                  'border': 'none',
                  'padding': '10px 10px 10px 5px',
                  'borderRadius': '50%',
                  'width': '40px',
                  'height': '80px',
                  'borderRadius': '0px 45px 45px 0',
                  'cursor': 'pointer',
                  'zIndex': '1',
                  'display': 'flex',
                  'alignItems': 'center',
                  'justifyContent': 'center',
                  'left': '0px',
              }} onClick={() => scrollLeft()}>
              <FaChevronLeft size={25} />
            </button>
            <button style={{
                  'position': 'absolute',
                  'top': '50%',
                  'transform': 'translateY(-50%)',
                  'backgroundColor': 'rgba(0, 0, 0, 0.2)',
                  'color': 'white',
                  'border': 'none',
                  'padding': '10px 5px 10px 10px',
                  'borderRadius': '50%',
                  'width': '40px',
                  'height': '80px',
                  'borderRadius': '45px 0 0 45px',
                  'cursor': 'pointer',
                  'zIndex': '1',
                  'display': 'flex',
                  'alignItems': 'center',
                  'justifyContent': 'center',
                  'right': '0px',
              }} onClick={() => scrollRight()}>
              <FaChevronRight size={25} />
            </button>
            </div>
        </div>
    );
}

function TreatmentCard({ text1, text2, text3, image1, image2, onClickAction }) {
    return (
        <div className='treatment-card-component-container' onClick={onClickAction}>
            <div className='treatment-card-component-in'>
                <div className='treatment-card-component-images'>
                    <div className='treatment-card-component-image'>
                        <img src={image1} height={"100%"} style={{ borderTopLeftRadius: "10px" }}></img>
                    </div>
                    <div className='treatment-card-component-image'>
                        <img src={image2} height={"100%"} style={{ borderTopRightRadius: "10px" }}></img>
                    </div>
                </div>
                <div className='treatment-card-component-content-area'>
                    <div className='treatment-card-component-text0'>
                        {/* SMP for men */}
                        {text1}
                    </div>
                    <div className='treatment-card-component-text1'>
                        {/* "I don't think about my hair anymore. I get dressed, get out of bed, and go to work, that's it. It looks so natural and so real! I just wish I had gotten it done sooner." */}
                        {text2}
                    </div>
                    <div className='treatment-card-component-text2'>
                        {/* - Travis */}
                        {text3}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Treatments;
